import {
  Inject,
  Component,
  OnInit,
  Renderer2,
  HostBinding,
  HostListener,
  AfterViewInit,
  PLATFORM_ID,
  Optional,
  AfterContentInit
} from "@angular/core";
import { Location,DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SingletonService } from "./services/singleton.service";
import { Subscription ,  Observable } from "rxjs";
import { environment }     from '../environments/environment';
import { ConfigService } from './services/config.service';
import { map } from 'rxjs/operators';
import { RouterOutlet, Router, ActivatedRoute,NavigationEnd } from "@angular/router";
import { Meta } from '@angular/platform-browser';
import * as _ from "lodash";
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Event as NavigationEvent } from "@angular/router";
import {fadeAnimation} from "./services/animation";
import {AppService} from "./app.service";
import { GtmMethodService } from './services/gtmmethods.service';
import { SEOService } from "./services/seo.service";
import { RESPONSE,REQUEST } from '../express.tokens';
import { HeaderComponentService } from "./component/header/header.service";
import { CatalogData } from "src/models/user.model";
import { gme } from "./app.constant";
import * as CryptoJS from 'crypto-js';
import { frk } from "./app.constant";
import { profileComponentService } from "./component/profile-form/profile.service";
import { FeedbackFormserviceService } from "./browse/feedback-formservice.service";

declare var ClientIP: any;
declare var retrieveIP:any;
declare var window:any;
declare var $: any;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, AfterViewInit, AfterContentInit {
  privateIP: any;
  toggleMainMenu: boolean;
  togglePosition: boolean;
  subscription: Subscription;
  contentDeliveryUrl: any;
  public publicKey$: Observable<string>;
  checkoutStatus: boolean = true;
  convertScript:any;
  countryData:Array<any>=environment.countryJsonData;
  enviromentSpecific:any=environment;
  rootFr:boolean;
  rootLM:boolean;
  isSessionExpire:boolean;
  animationsDisabled = false;
  timeStart = false;
  seconds = 15;
  clientX = 0;
  clientY = 0;
  loadGMscript:boolean;
  enableCookieBar:boolean;
  isLuigisBoxEnabled: boolean;
  unFollowPath: any; 
  productQty = [];
  previewId:any;
  private parsedBase64Key = CryptoJS.enc.Base64.parse(frk.FRKey);
  public encryptedData = null;
  @HostListener('window:pageshow', ['$event']) onPageShow(e){
    if (e.persisted) {
      if(this.singletonServ){
        this.singletonServ.sendMessage({reloadCompnt:true});
      }
   }
  }
  @HostListener('mousemove') onMove(e) {
  }
  @HostListener('keypress') onKeyPress(e) {

  }
  @HostListener('document:click', ['$event'])
  onBlockSession(event) {
    const that=this;
    this.constructSessionExpire();
    if(event.target.pathname == "/store/browse/feedback/feedback"){
      let query=event.target.search;
      let queryparam=query.split("=");
      this.router.navigate([event.target.pathname], { queryParams: { type: queryparam[1]} });
    } else if (event.target.tagName == 'A' || $(event.target).closest('a').length > 0) {
      const eventClass = event.target.getAttribute('class');
      const matchBVClass = /bv-content/;
      const bvButtonFound = matchBVClass.test(eventClass); 
      const href = event.target.getAttribute('href') ? event.target.getAttribute('href') : $(event.target).closest('a').attr('href');
      if (!href) {
      } else if(event.target.target != "_blank"){
        const url = new URL(href, href.indexOf('http') === -1 ? window.location.origin : undefined);
        if (url.origin == window.location.origin) {

          if(url.pathname == "/store/faqs" || url.pathname =="/store/promo-terms-conditions" || url.pathname =="/store/faqs-order" || url.pathname =="/store/faqs-account" ||url.pathname == "/store/faqs-product" ||url.pathname == "/store/gift-cards/faqs"){
            const faqId=url.hash;
            if(faqId == ""){
              this.router.navigateByUrl(url.pathname);
            } else{
              event.preventDefault();
              event.stopPropagation();
              this.singletonServ.scrollToTarget(faqId);
            }
          } else if(url.pathname == "/store/delivery"){
            const faqId=url.hash;
            if(faqId == ""){
              this.router.navigateByUrl(url.pathname);
            } else{
              event.preventDefault();
              event.stopPropagation();
              this.router.navigateByUrl(url.pathname+faqId);
            }
          } else{
           if(bvButtonFound !== true){
            this.router.navigateByUrl(url.pathname);
           }
          }
        }
      }
    }

  }

  @HostBinding('@.disabled')
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  constructSessionExpire(){
    const _timeout = new Date();
    const baseSite = this.singletonServ.catalogVersion;
    const _timestamp=moment.utc( _timeout ).valueOf();
    if(baseSite){
      if(this.getBrowserSessionData("prefered_lng")){
          if(this.singletonServ.alarmTime){
            if(_timestamp>this.singletonServ.alarmTime){
                for(let i=0; i<10000; i++){
                    window.clearInterval(i);
                }
            }else{
              _timeout.setMinutes(_timeout.getMinutes() + this.singletonServ.sessionTime);
              this.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
              this.singletonServ.sessionStarts=true;
            }
          }else{
            const _timeout = new Date();
            _timeout.setMinutes(_timeout.getMinutes() + this.singletonServ.sessionTime);
            this.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
            this.singletonServ.sessionStarts=true;
          }
    } else {
      this.sessionExpirez();
  }
}

  }
  IfZero(num) {
    return ((num <= 9) ? ("0" + num) : num);
  }
  enableCSHeader:boolean;
  constructor(
    @Inject(DOCUMENT) public dom,
    @Optional() @Inject(RESPONSE) private response: any,
    @Optional() @Inject(REQUEST) private request: any,
    public singletonServ: SingletonService,
    public location: Location,
    private renderer2: Renderer2,
    private config: ConfigService,
    public router: Router,
    public quickServ:AppService,
    public route: ActivatedRoute,
    private meta: Meta,
    public cookieService: CookieService,
    public gtmServ:GtmMethodService,
    private seoService: SEOService,
    private headerServ: HeaderComponentService,
    public profileServ: profileComponentService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const that=this;
    this.privateIP = "";//ClientIP;
     that.publicKey$ = config.getConfig().pipe(
      map(configValues => configValues.publicKey)
    );
    that.toggleMainMenu = false;
    if(location){
      if(location['_locationStrategy']){
    const _location = that.location['_locationStrategy']._platformLocation._location;
    this.router.events.subscribe(( event: NavigationEvent ) : void => {
          if ( event instanceof NavigationEnd ) {
            const baseSite=that.singletonServ.catalogVersion;
          if(baseSite){
            setTimeout(()=>{
              that.constructSEOService(baseSite.lngCode, event.url, _location);
            });
          }else{
            if(_location){
            if(_location.hostname){
            const _index = _.findIndex(this.countryData,(o)=>{
                  return (o.hostname == _location.hostname||o.serverName == _location.hostname);
            });
            if(_index !=-1){
              setTimeout(()=>{
                that.constructSEOService(this.countryData[_index].lngCode, event.url, _location);
              });
            }else{
              setTimeout(() => {
              that.constructSEOService(this.countryData[0].lngCode, event.url, _location);
              });
            }
           }}
          }
          that.constructPageHeader(event);
          return;
          }
      });
 }
}
this.route.queryParams.subscribe(params => {  
  this.previewId = params['previewId'];
});
}
constructSEOService(lngCode, _location_url,wLocation){
      const that=this;
      const _route = _location_url;
      const location = _route.split("?");
      const url = location[0];
      const _stateurl=url.replace('.','');
      const baseSite = this.singletonServ.catalogVersion;
      that.seoService.getValue(lngCode, _stateurl,wLocation).subscribe(response => {
        if(response != undefined) {
          if(response['tab-title']){
           this.seoService.setTabTitle(response['tab-title']);
          }else{
            this.seoService.setTabTitle(response.title);
          }
          this.seoService.updateTitle(response.title);
          this.seoService.updateDCSUBJECT(response["key-word"]);
          this.seoService.updateDescription(response.description);
          const _dcDescription:string=(response.DCDescription)?response.DCDescription:response.description;
          this.seoService.updateDCDescription(_dcDescription);
          const _ogDescription:string=(response['og-description'])?response['og-description']:response.description;
          this.seoService.updateOGDescription(_ogDescription);
          this.seoService.updateOGLocale(lngCode);
          this.seoService.updateOGImage();
          this.seoService.updateOGURL(_stateurl);
          this.seoService.createCanonicalURL(_stateurl);
          this.seoService.updateAlternateLink("x-default", response["url-UK"] == "" ?  _stateurl : response["url-UK"]);
          this.seoService.updateAlternateLink("en-gb", response["url-UK"] == "" ?  _stateurl : response["url-UK"]);
          this.seoService.updateAlternateLink("en-us", response["url-US"] == "" ?  _stateurl  : response["url-US"]);
          this.seoService.updateAlternateLink("en-ca", response["url-US"] == "" ?  _stateurl : response["url-US"]);
          this.seoService.updateAlternateLink("de-de", response["url-DE"] == "" ?  _stateurl : response["url-DE"]);
          this.seoService.updateAlternateLink("de-at", response["url-DE"] == "" ?  _stateurl : response["url-DE"]);
          this.seoService.updateAlternateLink("en-ie", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("ga-ie", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-be", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("be-be", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-nl", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("nl-nl", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-lu", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("lb-lu", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-ee", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("et-ee", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-at", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-sk", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("sk-sk", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-pt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("pt-pt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-fi", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("fi-fi", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-si", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("sl-si", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-mt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("mt-mt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-dk", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("dk-dk", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-bg", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("bg-bg", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-cz", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("cs-cz", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-hr", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("hr-hr", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-ad", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("ca-ad", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-sm", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("it-sm", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-ch", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-li", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("it-li", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-lt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("lt-lt", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-is", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("is-is", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-al", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("sq-al", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("no-no", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-no", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("fr-mc", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
          this.seoService.updateAlternateLink("en-mc", response["url-EU"] == "" ?  _stateurl : response["url-EU"]);
        }else {
          this.seoService.updateOGLocale(lngCode);
          this.seoService.updateOGImage();
        }
      if(window.location.pathname === "/store/index" && !document.getElementById('JSONLD_logo'))this.seoService.setJSONLDLogo()
      if(window.location.pathname === "/store/index" || "/store/contact-us" || "/store/about-us") this.seoService.setJSONLDForHP(lngCode , baseSite);
      });
}
constructPageHeader(event){
  const that=this;
  this.dom.querySelector('body').classList.remove("overlay-scroll-hidden");
  const _path =event.url.slice(1).split("/"); 
  const splitParamPath = event.url.split("?");
  const pathWithoutQuery = splitParamPath[0];
  let baseSite = this.singletonServ.catalogVersion;
/*    if(baseSite){
      const allCookies: {} = this.cookieService.getAll();
    if (baseSite.isoCode === "US" && !allCookies['cookiestored-US'] ){
      this.enableCookieBar = true;
    }
    else
    {
      this.enableCookieBar = false;
    }
    } */
  const page= _path[_path.length-1];
  if( _path[0] === "checkout" ){
    if (page === 'checkout') {
      this.singletonServ.welcomeTab = true;
      this.singletonServ.shippingTab = false;
      this.singletonServ.confirmationtab = false;
    }else if (page === 'login') {
      this.singletonServ.welcomeTab = true;
      this.singletonServ.shippingTab = false;
      this.singletonServ.confirmationtab = false;
    } else if (page === 'shipping') {
      this.singletonServ.welcomeTab = false;
      this.singletonServ.shippingTab = true;
      this.singletonServ.confirmationtab = false;
    } else if (page === 'mbOrderConfirmResponse') {
      this.singletonServ.welcomeTab = false;
      this.singletonServ.shippingTab = false;
      this.singletonServ.confirmationtab = true;
    }else if(page.search('expressCheckout') !=-1){
      this.singletonServ.welcomeTab = false;
      this.singletonServ.shippingTab = true;
      this.singletonServ.confirmationtab = false;
    }else if (page.search('mbOrderConfirmResponse')!=-1) {
      this.singletonServ.welcomeTab = false;
      this.singletonServ.shippingTab = false;
      this.singletonServ.confirmationtab = true;
    }
    that.checkoutStatus = false;
    that.switchSession();
  } else if(page.search('update-your-preferences')  != -1 ){
    that.checkoutStatus = false;
    that.switchSession();
  }else if(event.url.search('/marketing/unsubscribe')!=-1){
    that.checkoutStatus = false;
    that.switchSession();
  } else if (  _path[1] === "storeportal" ) {
    that.checkoutStatus = false;
  } else if(_path[1] === "bireport"){
    that.checkoutStatus = false;
  } else if(event.url=="/store/update-your-preferences/unsubscribe"||event.url=="/store/update-your-preferences/unsubscribe/.") {
    that.checkoutStatus = false;
    that.switchSession();
  }else if(event.url=="/store/update-your-preferences/confirmation"||event.url=="/store/update-your-preferences/confirmation/.") {
    that.checkoutStatus = false;
    that.switchSession();
  }else if(event.url=="/store/marketing/unsubscribe"||event.url=="/store/marketing/unsubscribe/.") {
    that.checkoutStatus = false;
    that.switchSession();
  } else if(event.url=='/store/mothers-day-unsubscribe' || event.url=='/store/mothers-day-unsubscribe/.'){
    that.checkoutStatus = false;
    that.switchSession();
  }else if(pathWithoutQuery=='/store/marketing-unsubscribe-pause' || pathWithoutQuery=='/store/marketing-unsubscribe-pause/.'){
    that.checkoutStatus = false;
    that.switchSession();
  }else if(pathWithoutQuery=='/store/marketing-competition' || pathWithoutQuery=='/store/marketing-competition/.'){
    that.checkoutStatus = false;
    that.switchSession();
  } else {
    const page= _path[_path.length-1];
    if(page=="mbBasket"){
      that.singletonServ.basketTab=true;
      that.singletonServ.sampleTab=false;
      that.singletonServ.checkOutTab=false;
    }else if(page=="mbSamples"){
      that.singletonServ.basketTab=false;
      that.singletonServ.sampleTab=true;
      that.singletonServ.checkOutTab=false;
    }else if(page=="mbcart"){
      that.singletonServ.basketTab=true;
      that.singletonServ.sampleTab=false;
      that.singletonServ.checkOutTab=false;
    }
    that.checkoutStatus = true;
    that.switchSession();
  }

}
switchSessionTernarySites(_path){
  const that=this;
  const _timeout = new Date();
  const baseSite = that.singletonServ.catalogVersion;
  if(baseSite){
    if(that.getBrowserSessionData("prefered_lng")){
        const _timestamp=moment.utc( _timeout ).valueOf();
        if(that.singletonServ.alarmTime){
          if(_timestamp>that.singletonServ.alarmTime){
            if (isPlatformBrowser(this.platformId)) {
              this.removeBrowserSessionData("order");
              const _sessionNames=Object.keys(localStorage);
              if(_sessionNames.length){
                  _sessionNames.map((obj)=>{
                      that.removeBrowserSessionData(obj);
                  });
              }
           }
              this.clearBrowserSessionData();
              this.singletonServ.cartObj=undefined;
              that.singletonServ.alarmTime=undefined;
              that.singletonServ.sessionStarts=false;
              that.setBrowserSessionData("prefered_lng", JSON.stringify(that.singletonServ.catalogVersion));
              for(let i=0; i<10000; i++){
                window.clearInterval(i);
            }
            if (  _path[1] === "storeportal" ) {
              that.router.navigate(['store','storeportal']);
            } else if(_path[1] === "bireport"){
              that.router.navigate(['store','bireport']);
            }
          }else{
            const _timeout = new Date();
            _timeout.setMinutes(_timeout.getMinutes() + that.singletonServ.sessionTime);
            that.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
            that.singletonServ.sessionStarts=true;
          }
  }else{
    const _timeout = new Date();
    _timeout.setMinutes(_timeout.getMinutes() + that.singletonServ.sessionTime);
    that.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
    that.singletonServ.sessionStarts=true;
  }
}else{
  if (isPlatformBrowser(this.platformId)) {
    this.removeBrowserSessionData("order");
    const _sessionNames=Object.keys(localStorage);
    if(_sessionNames.length){
        _sessionNames.map((obj)=>{
            that.removeBrowserSessionData(obj);
        });
    }
 }

this.clearBrowserSessionData();
this.singletonServ.cartObj=undefined;
that.singletonServ.alarmTime=undefined;
that.singletonServ.sessionStarts=false;
that.setBrowserSessionData("prefered_lng", JSON.stringify(that.singletonServ.catalogVersion));
for(let i=0; i<10000; i++){
  window.clearInterval(i);
}
if (  _path[1] === "storeportal" ) {
  that.router.navigate(['store','storeportal']);
} else if(_path[1] === "bireport"){
  that.router.navigate(['store','bireport']);
}
}
}
}
switchSession(){
  const that=this;
  const _timeout = new Date();
  const baseSite = that.singletonServ.catalogVersion;
  if(baseSite){
    if(that.getBrowserSessionData("prefered_lng")){
        const _timestamp=moment.utc( _timeout ).valueOf();
        if(!this.getBrowserSessionData(baseSite.csAssistant)){
         if(that.singletonServ.alarmTime){
          if(_timestamp>that.singletonServ.alarmTime){
            if (isPlatformBrowser(this.platformId)) {
              this.removeBrowserSessionData("order");
              const _sessionNames=Object.keys(localStorage);
              if(_sessionNames.length){
                  _sessionNames.map((obj)=>{
                      that.removeBrowserSessionData(obj);
                  });
              }
           }
              this.clearBrowserSessionData();
              this.singletonServ.cartObj=undefined;
              that.singletonServ.alarmTime=undefined;
              that.singletonServ.sessionStarts=false;
              that.setBrowserSessionData("prefered_lng", JSON.stringify(that.singletonServ.catalogVersion));
              for(let i=0; i<10000; i++){
                window.clearInterval(i);
              }
                this.singletonServ.sendMessage({clearCart:true});
                that.router.navigate(['store','global','sessionExpired']);
          }else{
            const _timeout = new Date();
            _timeout.setMinutes(_timeout.getMinutes() + that.singletonServ.sessionTime);
            that.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
            that.singletonServ.sessionStarts=true;
          }
  }else{
    const _timeout = new Date();
    _timeout.setMinutes(_timeout.getMinutes() + that.singletonServ.sessionTime);
    that.singletonServ.alarmTime =moment.utc( _timeout ).valueOf();
    that.singletonServ.sessionStarts=true;
  }
  }
 }else{
  if(baseSite.csAgent){
    this.singletonServ.sendMessage({invalidAgentToken:true});
  }
  if (isPlatformBrowser(this.platformId)) {
    this.removeBrowserSessionData("order");
    const _sessionNames=Object.keys(localStorage);
    if(_sessionNames.length){
        _sessionNames.map((obj)=>{
            that.removeBrowserSessionData(obj);
        });
    }
 }

this.clearBrowserSessionData();
this.singletonServ.cartObj=undefined;
that.singletonServ.alarmTime=undefined;
that.singletonServ.sessionStarts=false;
that.setBrowserSessionData("prefered_lng", JSON.stringify(that.singletonServ.catalogVersion));
for(let i=0; i<10000; i++){
  window.clearInterval(i);
}
this.singletonServ.sendMessage({clearCart:true});
that.router.navigate(['store','global','sessionExpired']);
}
}
}


  ngOnInit() {
    this.renderer2.addClass(document.body, 'ready');
    const that=this;
    const frScript:string = this.enviromentSpecific.freshrelevance;
    this.singletonServ.frScript=frScript;
    const allCookies: any = this.cookieService.getAll();
    if(allCookies['cookiestored-gb'] ||allCookies['cookiestored-US'] || allCookies['cookiestored-eu'] || allCookies['cookiestored-de'] ){
      this.enableCookieBar=true;
    }else{
      this.enableCookieBar=false;
    }

    if (isPlatformBrowser(this.platformId)) {
    const _hostname = that.location['_locationStrategy']._platformLocation._location.hostname;
    const _index = _.findIndex(this.countryData,(o)=>{
          return (o.hostname == _hostname||o.serverName == _hostname);
    });
    if(_index !=-1){
      this.singletonServ.catalogVersion = this.countryData[_index];
      this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
      this.gtmServ.gtmOriginalLocation(this.countryData[_index]);
      this.setLang(this.countryData[_index].lngCode);
      this.setBVScript();
      $('#moltonbrown').val(this.countryData[_index].hostname);
    } else if (this.getBrowserSessionData("prefered_lng")){
      const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
      this.singletonServ.catalogVersion = _data;
      this.gtmServ.gtmOriginalLocation(_data);
      this.setLang(_data.lngCode);
      this.setBVScript();
      $('#moltonbrown').val(_data.hostname);
    }else{
       this.route.queryParams.subscribe(params => {
     const myParam =  params['country_store'];
    if (myParam) {
      const _index = _.findIndex(this.countryData, function(o) {
        return o.lngCode == myParam;
      });
      if(_index !=-1 ){
        this.singletonServ.catalogVersion = this.countryData[_index];
        this.gtmServ.gtmOriginalLocation(this.countryData[_index]);
        this.setLang(this.countryData[_index].lngCode);
        this.setBVScript();
        this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
        $('#moltonbrown').val(this.countryData[_index].hostname);
      }else if (this.getBrowserSessionData("prefered_lng")){
              const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
              $('#moltonbrown').val(_data.hostname);
              this.singletonServ.catalogVersion = _data;
              this.gtmServ.gtmOriginalLocation(_data);
              this.setLang(_data.lngCode);
              this.setBVScript();
      }else{
        this.setCatlogVersion();
      }
    } else{
      this.setCatlogVersion();
    }
  });
    }
    this.updateRobot();    
  }
   this.appendScripts(this.singletonServ.catalogVersion.lngCode);
  }
  
  appendScripts(region: string) {
    const script = this.renderer2.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js';
    script.setAttribute('data-environment', 'production');

    if (region === 'de') {
      script.setAttribute('data-client-id', 'klarna_live_client_N1lsR2IkVlR1WDg1bXZUJTJLdzZ6d1ZYTnllIylINigsZDlhODFjZjktMmU1My00MzdmLWIzYjItNGMxNGM5OThhNmE1LDEsWG5aTklhWTM5UlFsQVUya0VjV3R3Z0JrV2Jab0cwMldYYSs5bmM0aGJrWT0');
    } else if (region === 'en') {
      script.setAttribute('data-client-id', 'klarna_live_client_b3lEVGhSIyNJJG91R3Ryanl4aHVxV3IzdkE1R0FEdCEsZDU2NjAzNzUtZWNhZS00ZmM5LTg2NDEtOTNmYmI4NTJmNjhlLDEsZnE0WTNST0wrVmhSOTN4UE4zSjNVdXBpNnRrZytuVjlFN0NkRnBpb2ZmWT0');
    }

    // this.renderer2.appendChild(document.body, script);
    const body = this.renderer2.selectRootElement('body', true);
    const firstChild = body.firstChild;
  
    // Insert the script at the top of the body
    this.renderer2.insertBefore(body, script, firstChild);
  }
  updateRobot(){   
    const _indexFollow=this.enviromentSpecific.live;
    const winLocation = window.location.pathname; 
    const countryCode = this.singletonServ.catalogVersion.lngCode;    
    this.quickServ.getI8nContent(countryCode).subscribe((resp)=>{ 
      if (resp["robotNotFollow"]) {
        this.unFollowPath = resp["robotNotFollow"];
      }
      if (_indexFollow && winLocation !== undefined && this.unFollowPath !== undefined) {
        let noIndex = this.unFollowPath.filter(path => {
          if (winLocation == path["pagePath"]) return true;
        })
        if (noIndex.length) {
          this.updateRobotIndex("robots", "noindex");
        } else {
          this.updateRobotIndex("robots", "INDEX,FOLLOW");
        }
      } else {
        this.updateRobotIndex("robots", "noindex");
      }
    });
 }
  updateRobotIndex(name,content){
    const head= this.dom.getElementsByTagName('head')[0];
    const _meta= this.dom.createElement('meta');
    _meta.name=name;
    _meta.content= content;
    head.appendChild(_meta);
  }
  setLang(lang: string) {
    this.quickServ.getI8nContent(lang).subscribe((resp)=>{
      this.singletonServ.translateData=resp;
    });
  } 
  loadScript(url){
    this.singletonServ.loadScript(url).then(() => {
      this.loadGMscript=true;
    });
  }

appendGTMstart(){
      const baseSite=this.singletonServ.catalogVersion;
      const langPath = `assets/js/gtm-${baseSite.lngCode || 'en'}.js`;
      const body= this.dom.getElementsByTagName('head')[0];
      const script= this.dom.createElement('script');
      script.type= 'text/javascript';
      script.async="async";
      script.src=langPath;
      body.appendChild(script);
}
setOptimizelyScript(){
  const baseSite=this.singletonServ.catalogVersion;
  if(baseSite.pciOptimizely){
    const head= this.dom.getElementsByTagName('body')[0];
    const script= this.dom.createElement('noscript');
    const _iframe= this.dom.createElement('iframe');
   _iframe.src= baseSite.pciOptimizely;
    // _iframe.src="https://cdn-pci.optimizely.com/js/20496642582.js";
    _iframe.setAttribute("height","0");
    _iframe.setAttribute("width","0");
    _iframe.setAttribute("style","display:none;visibility:hidden;");
    script.appendChild(_iframe);
    head.appendChild(script);
  }
}

setGTMScript(){
  const baseSite=this.singletonServ.catalogVersion;
  if(baseSite.gtmsrc){
      const head= this.dom.getElementsByTagName('body')[0];
      const script= this.dom.createElement('noscript');
      const _iframe= this.dom.createElement('iframe');
      _iframe.src= baseSite.gtmsrc;
      _iframe.setAttribute("height","0");
      _iframe.setAttribute("width","0");
      _iframe.setAttribute("style","display:none;visibility:hidden;");
      script.appendChild(_iframe);
      head.appendChild(script);
  }
}
  setBVScript(){
    const baseSite=this.singletonServ.catalogVersion;
    if (!('$TB' in window)) {
      this.addThirdPartyScript(baseSite.fr_script);
    }
    const queryStatus = window.location.search.replace('?', '');
    let _lng;
    if(baseSite.isoCode=="GB"||baseSite.isoCode=="EU"){
      _lng="en-GB";
    } else if(baseSite.isoCode=="US"){
      _lng="en";
    } else{
      _lng="de";
    }
    this.autoBlocker(baseSite.isoCode); 
    setTimeout(() => {
      if(!this.previewId){               
        this.CookeBannerloadScript(baseSite.isoCode);
      }
    }, 1000);

    this.singletonServ.googleScriptKey=`https://maps.googleapis.com/maps/api/js?language=${(_lng)?_lng:'en'}&libraries=geometry,places&key=${(gme.googleMapKey)}&callback=initMap`;
    if (queryStatus.indexOf('ASM') != -1) {
  	   this.enableCSHeader=true;
  	} else  if(this.getBrowserSessionData(baseSite.csAssistant)){
      this.enableCSHeader=true;
   } else  if(this.getBrowserSessionData(baseSite.csCustomer)){
			this.enableCSHeader=true;
	 } else{
	   this.enableCSHeader=false;
	 }
    this.setOptimizelyScript();
    this.appendGTMstart();
    this.setGTMScript();
    if(baseSite.bv){
        const head= this.dom.getElementsByTagName('head')[0];
        const script= this.dom.createElement('script');
        script.type= 'text/javascript';
        script.async="async";
        script.src= baseSite.bv;
        head.appendChild(script);
    }
    this.checkUserTypo();
 }
 addThirdPartyScript(src){
  const head= this.dom.getElementsByTagName('head')[0];
  const script= this.dom.createElement('script');
  script.type= 'text/javascript';
  script.async="async";
  script.src=src;
  head.appendChild(script);
}
 checkUserTypo(){
  const baseSite=this.singletonServ.catalogVersion;
   if(this.getBrowserSessionData(baseSite.reg)){
    const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
    this.quickServ.generateCartToken(baseSite).subscribe((tokenizer:any)=>{
      this.getUserData(tokenizer.access_token);
   });
   }
 }

 autoBlocker(countryCode)
 {
  if(countryCode=="GB"){
   var autoBlock ="https://cdn.cookielaw.org/consent/f738b743-e1c8-428e-a045-c855b0353b5d/OtAutoBlock.js";
   const script= this.dom.createElement('script');
   script.type= 'text/javascript';
   script.async= true;
   script.src= autoBlock;
   document.getElementsByTagName('head')[0].appendChild(script);
  }
  else if (countryCode=="DE")
  {
    var autoBlock ="https://cdn.cookielaw.org/consent/1b8cc0d8-71f5-4410-98c3-43d75b6cc827/OtAutoBlock.js";
    const script= this.dom.createElement('script');
    script.type= 'text/javascript';
    script.async= true;
    script.src= autoBlock;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  else if (countryCode=="US")
  {
    var autoBlock ="https://cdn.cookielaw.org/consent/faa1ebed-ef89-4776-b881-6df77f341bfe/OtAutoBlock.js";
    const script= this.dom.createElement('script');
    script.type= 'text/javascript';
    script.async= true;
    script.src= autoBlock;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
  else if (countryCode=="EU")
  {
    var autoBlock ="https://cdn.cookielaw.org/consent/6db8795c-9792-4fa1-9ee8-accd3f97c11c/OtAutoBlock.js";
    const script= this.dom.createElement('script');
    script.type= 'text/javascript';
    script.async= true;
    script.src= autoBlock;
    document.getElementsByTagName('head')[0].appendChild(script);

  }
 }
 CookeBannerloadScript(countryCode) {​​​​​
  var isFound = false;
  var scripts = document.getElementsByTagName("script")
  for (var i = 0; i < scripts.length; ++i) {​​​​​
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {​​​​​
          isFound = true;
      }​​​​​
  }​​​​​
  if (!isFound) {
    if (countryCode=="US")
          {​​​​​
      var dynamicScripts = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
          let node = document.createElement('script');
          node.src = dynamicScripts;
          node.type = 'text/javascript';
          node.async = false;
          node.charset = 'utf-8';
          
            node.setAttribute("data-domain-script","faa1ebed-ef89-4776-b881-6df77f341bfe" )
            document.getElementsByTagName('head')[0].appendChild(node);

          }
          // if(countryCode=="GB"){
          // // OneTrust Cookies Consent Notice start for moltonbrown.co.uk
          //   node.setAttribute("data-domain-script","f738b743-e1c8-428e-a045-c855b0353b5d" )
          // }
          // else if (countryCode=="US")
          // {
          //   node.setAttribute("data-domain-script","faa1ebed-ef89-4776-b881-6df77f341bfe" )

          // }
          // else if (countryCode=="DE")
          // {
          //   // OneTrust Cookies Consent Notice start for moltonbrown.de
          //   node.setAttribute("data-domain-script","1b8cc0d8-71f5-4410-98c3-43d75b6cc827" )

          // }
          // else if (countryCode=="EU")
          // {
          //   // OneTrust Cookies Consent Notice start for moltonbrown.eu
          //   node.setAttribute("data-domain-script","6db8795c-9792-4fa1-9ee8-accd3f97c11c" )

          // }
          // else{

          //   node.setAttribute("data-domain-script","f738b743-e1c8-428e-a045-c855b0353b5d" )

          // }

          //document.getElementsByTagName('head')[0].appendChild(node);
      }​​​​​
    }

 getUserData(tokenId) {
  const baseSite = this.singletonServ.catalogVersion;
  const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
  user['token']=tokenId;
  this.quickServ.getUserData(baseSite,tokenId, user.email).subscribe(
    (resp:any) => {
      this.singletonServ.userDetail=resp;
      this.singletonServ.loggedIn=true;
      user['customerId']=resp.customerId;
      user['mbcustomer']=resp.customerPlacedOrders;
      user['isExpressCheckout']=(resp['isExpressCheckout'])?true:false;
      this.setBrowserSessionData(baseSite.reg,JSON.stringify(user));
    },
    (err:any) => {

    }
  );
}
  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }
  setCatlogVersion(){
    const _index = _.findIndex(environment.countryJsonData, function(o) {
      return o.locale == "en-GB";
    });
    if(_index !=-1){
      this.setBrowserSessionData("prefered_lng", JSON.stringify(environment.countryJsonData[_index]));
      $('#moltonbrown').val(environment.countryJsonData[_index].hostname);
      this.singletonServ.catalogVersion = environment.countryJsonData[_index];
      this.gtmServ.gtmOriginalLocation(environment.countryJsonData[_index]);
      this.setLang(environment.countryJsonData[_index].lngCode);
      this.setBVScript();
    }

  }
  progress:any;
  ngAfterViewInit() {
    const that=this;
    const _basesite:any = this.singletonServ.catalogVersion;
    this.subscription = this.singletonServ.getMessage().subscribe(message => {
      if (message.moltonSideumenu) {
         that.toggleMainMenu = message.moltonSideumenu.state;
         setTimeout(() => {
          that.togglePosition = message.moltonSideumenu.state;
         }, 1000);
      }
   });
   this.dom.addEventListener("createNewUser", function(e) {
    that.createNewUserFR(e);
  });
   this.dom.addEventListener("add-to-cart", function(e) {
        that.addFRToBasket(e);
    });
    this.dom.addEventListener("create-entry", function(e) {
      that.addLMToBasket(e);
  });
  window.addEventListener("message", function(event:any) {
    if(event.data){
      if(event.data.messageType == "product-result") {
        that.setBrowserSessionData(_basesite.personaId,JSON.stringify({_id:event.data.primaryProduct,_identity:event.data}));
        that.router.navigate(['/store/fragrance/fragrance-finder/displayFFProducts']);
      }
    }
   });
   if(_basesite && _basesite.catalogversionId){
   this.headerServ.getCatalogData(_basesite).subscribe((resp:CatalogData) => {
    if(resp) this.singletonServ.setCatalogData(resp);

   });
  }
  this.router.events.subscribe(( event: NavigationEvent ) : void => {
    if (event instanceof NavigationEnd) {
      document.body.classList.remove('ready');
    }
  });
  //og script for main.js
  
  if(_basesite.isoCode === "GB" && !document.querySelector('#og-offer-js')){
  let ogScript = this.renderer2.createElement('script');
  ogScript.type = 'text/javascript';
  ogScript.id = 'og-offer-js'
  ogScript.src = 'https://staging.static.ordergroove.com/bcfe3d0f2c934e06b744327b186064b0/main.js';
  ogScript.setAttribute("defer", "defer");
  this.renderer2.appendChild(document.head, ogScript);
  }
 }
ngAfterContentInit(){
  this.setServerSideCookie();
}
  getCountrySite(type:string){
    let host;
    if( type && (type=='us' || type.toLowerCase()=='us')){
      host=".moltonbrown.com";
    } else   if(type && (type=='uk'  || type.toLowerCase()=='uk')){
      host=".moltonbrown.co.uk";
    }  else  if(type && (type=='eu' || type.toLowerCase()=='eu') ){
      host=".moltonbrown.eu";
    }  else  if(type && (type=='de'  || type.toLowerCase()=='de')){
      host=".moltonbrown.de";
    }
  return host;
  }

  setServerSideCookie(){
    const that =this;

   this.route.queryParams.subscribe((params:any) => {
     const _ranMIDquery = params["ranMID"];
     const _ranEAIDquery = params["ranEAID"];
     const _ranSiteIDquery = params["ranSiteID"];
     const _countrycode = this.getCountrySite(params["country_store"]);
    if( _ranSiteIDquery && _ranMIDquery ){
      const cookieName = 'rmStoreGateway';
      const date=new Date();
      const _auldTimestamp = Math.round(new Date().getTime() / 1000);
      const  landDateTime = that.formatDate(date);
      const _expires = new Date(Date.now() + 31536000000);
      const _expireStr = _expires.toUTCString();
         const _restVal =`;Expires=${_expireStr};path=/;domain=${_countrycode};secure;SameSite=Lax;`;
       const encodeValue =encodeURIComponent(`amid:${_ranMIDquery}|ald:${landDateTime}|atrv:${_ranSiteIDquery}|auld:${_auldTimestamp}`);
       const cookieStor = cookieName +`=${encodeValue}${_restVal}`;
      if(that.response){
       if( that.response.setHeader )
       that.response.setHeader('Set-Cookie', [cookieStor]);
   }
 }
 });
  }
 formatDate (ranDate) {
  var dd = this.toTwoCharacters(ranDate.getUTCDate());
  var mm =  this.toTwoCharacters(ranDate.getUTCMonth() + 1);
  var yyyy = "" + ranDate.getUTCFullYear();
  var hr =  this.toTwoCharacters(ranDate.getUTCHours());
  var mn =  this.toTwoCharacters(ranDate.getUTCMinutes());
  return yyyy + mm + dd + "_" + hr + mn;
}
toTwoCharacters (input) {
  input += "";
  if (input.length === 1) {
      input = "0" + input;
  }
  return input;
}
createNewUserFR(event){
  this.rootLM=true;
  this.rootFr=false; 
  this.createNewUser(event);
}
  addFRToBasket(event){
    this.rootFr=true;
    this.rootLM=false;
    this.  addToBasket(event);
   }
   addLMToBasket(event){
    this.rootLM=true;
    this.rootFr=false;
    this.  addToBasket(event);
   }
  scrollHandler() {}

  onActivate(e, parent){
    parent.scrollTop = 0;
  }


  addToBasket(event) {
    event.preventDefault();
    event.stopPropagation();
    const productObj = event.detail;
    const baseSite = this.singletonServ.catalogVersion;
        if (this.getBrowserSessionData(baseSite.reg)) {
          const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
          this.singletonServ.loggedIn = true;
          if (!user.code) {
            this.createCart(user.email,productObj,true);
          } else {
            if(user["token"]){
              this.addToCart(user["token"],user.email,user.code,productObj);
            }else{
              this.quickServ.generateCartToken(baseSite).subscribe(
                resp => {
                   const token = resp["access_token"];
                   user.token= resp["access_token"];
                   this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                   this.addToCart(token,user.email,user.code,productObj);
                },err=>{

                });
            }
          }
        } else {

          if (!this.getBrowserSessionData(baseSite.guest)) {
            this.createCart('anonymous',productObj,false);
          } else {
            const _guest = JSON.parse(this.getBrowserSessionData(baseSite.guest));
            const cartId =  _guest["guid"];
            const tokenId =_guest["token"];
            if(cartId){
                if(tokenId){
                  this.addToCart(tokenId,'anonymous',cartId,productObj);
                }else{
                  this.quickServ.generateCartToken(baseSite).subscribe(
                    resp => {
                      const token = resp["access_token"];
                      _guest.token= resp["access_token"];
                      this.setBrowserSessionData(baseSite.reg, JSON.stringify(_guest));
                      this.addToCart(tokenId,'anonymous',cartId,productObj);
                    },err=>{

                    });
                }
          }else{
            this.createCart('anonymous',productObj,false);
          }
        }
        }

  }

  createCart(email,productObj,logged){
    const baseSite = this.singletonServ.catalogVersion;
    this.quickServ.generateCartToken(baseSite).subscribe(
      resp => {
        const token = resp["access_token"];

    this.quickServ.generateCartId(baseSite,resp["access_token"],email).subscribe(
          data => {
            if(logged){
              const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
              user['code']=data['code'];
              user['guid']=data["guid"];
              user['token']=token;
              this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
              this.addToCart(token,email,user['code'],productObj);
            }else{
              const _user = {token:'',guid:''};
              _user["guid"]=data["guid"];
              _user['code']=data['code'];
              _user['token']=resp["access_token"];
              this.setBrowserSessionData(baseSite.guest, JSON.stringify(_user));
              this.addToCart(resp["access_token"],email,data["guid"],productObj);
            }

          },err=>{
          });
        },
        error => {}
      );
  }
  pageUrl1(dataurl){
    const pageUrl = dataurl.product.url;
    return pageUrl.split("/");

    }
  addToCart(token,email,code,productObj){
    const baseSite = this.singletonServ.catalogVersion;
    this.singletonServ.sendMessage({"cartLoading":"true"});
    this.quickServ.addToBasket(baseSite,token,email,code,productObj).subscribe((response:any)=>{
      const _prodIndex=_.findIndex(response.entries,(item)=>{return item.product.code==productObj['product']["code"]});
      const _frprdouct=response.entries[_prodIndex];
      if(this.rootFr){
        const obj = {
          code: productObj['product']["code"],
          showCartPopUp: true,
          unstreamCartData:response
        };
        this.singletonServ.sendMessage(obj);
      } else{
        const obj = {
          refreshCart: true,
          unstreamCartData:response
        };
        this.singletonServ.sendMessage(obj);
      }
      if(this.rootLM){
        if(response.entries)this.singletonServ.sendMessage({frProducts:response.entries})
        window.scroll({
          top: 40,      
          left: 0,      
          behavior: "smooth"      
        });
        }
      const pageUrlCat = this.pageUrl1(_frprdouct);
      const pageUrlMainCat= pageUrlCat[1].split('-').join(' & ').toUpperCase();

    let _subCatg;
   if(pageUrlCat[2]=="bath-body-gift-sets" || pageUrlCat[2]=="body-lotion-cream" || pageUrlCat[2]=="bath-shower-gel"){
    if(pageUrlCat[2]=="bath-body-gift-sets"){
     let urlsplit:any=pageUrlCat[2].split("-")
     let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]+" "+urlsplit[3]
     _subCatg=subcat.toUpperCase();
    }
    else if(pageUrlCat[2]=="body-lotion-cream"){
     let urlsplit:any=pageUrlCat[2].split("-")
     let subcat=urlsplit[0]+" "+urlsplit[1]+" & "+urlsplit[2]
     _subCatg=subcat.toUpperCase();
    }
    else if(pageUrlCat[2]=="bath-shower-gel"){
     let urlsplit:any=pageUrlCat[2].split("-")
     let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]
     _subCatg=subcat.toUpperCase();
    }

  }
  else{
   let subcat=pageUrlCat[2].split("-").join(" ");
   _subCatg=subcat.toUpperCase();
  }
   const pageUrlSubCat= _subCatg.toUpperCase();
   let saleprice;
   if(_frprdouct.product.originalPrice){
     saleprice=_frprdouct.product.price.value
   }
   else{
     saleprice=''
   }

   const cartDetails = {
     'currencyCode': _frprdouct.product.price.currencyIso,
     'categoryList':  pageUrlMainCat+" - "+_subCatg,
     'productName': _frprdouct.product.productDisplayName,
     'productID': _frprdouct.product.code,
     'productPrice': (_frprdouct.product.price)?(_frprdouct.product.price.value):'',
     'productBrand': "Molton Brown",
   'productCategory': pageUrlSubCat,
     'productVariant': (_frprdouct.product.productVariant)? _frprdouct.product.productVariant : "",
   'productQuantity':_frprdouct.quantity,
     'size':_frprdouct.product.size,
     'reviewRating': (_frprdouct.product.productAverageRating)?_frprdouct.product.productAverageRating:'0',
     'reviewsCount': (_frprdouct.product.reviewCount)?_frprdouct.product.reviewCount:'0',
     'saleStatus': (_frprdouct.product.originalPrice)?'True':'False',
     'stockLevel': (_frprdouct.product.stock.stockLevelStatus == "inStock")?"True":"False",
     'productStockLevelNumber':(_frprdouct.product.stock.stockLevel)?_frprdouct.product.stock.stockLevel:'',
      'deleveryType':(_frprdouct.product.productEdition)?_frprdouct.product.productEdition:'',
       'salePrice':saleprice



   }

   if(_frprdouct.product.price['formattedValue']){
    cartDetails['productPrice']=_frprdouct.product.price.value;
  }
  else{
    if(_frprdouct.product.originalPrice){
      const originalPrice= _frprdouct.product.originalPrice;
    const _originalPrice = originalPrice.match(/[\d\.]+/);
    if(_originalPrice){
      cartDetails['productPrice']=_originalPrice[0];
    }else{
      cartDetails['productPrice']=originalPrice;
    }
    }
  }
    this.gtmServ.gtmAddToCart(cartDetails);
    this.singletonServ.sendMessage({inStock:true});


    },err=>{
      if(err.error){
        if(err.error["errors"]){
          if(err.error["errors"][0]){
            if(err.error["errors"][0]['type']== "InvalidTokenError") {
              if(!baseSite.csAgent){
              this.quickServ.generateCartToken(baseSite).subscribe(
                (resp:any) => {
                  const _reg=(email!='anonymous')?true:false;
                  if(_reg){
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                    this.addToCart(resp["access_token"],email,code,productObj);
                  }else{
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.guest));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.guest, JSON.stringify(user));
                    this.addToCart(resp["access_token"],email,code,productObj);
                  }
                });
              }else{
                this.singletonServ.sendMessage({invalidAgentToken:true});
              }
            } else if(err.error["errors"][0]['type']== "InsufficientStockError"){
              this.singletonServ.sendMessage({outofStock:err.error["errors"][0]});
          }
          else if(err.error["errors"][0]['type']== "CartError") {
           
            if(!baseSite.csAgent){
            this.quickServ.generateCartToken(baseSite).subscribe(
              (resp:any) => {
                    const _reg=(email!='anonymous')?true:false;
                    if(_reg){
                      const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                      user.token= resp["access_token"];
                      this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                      this.createCart(user.email,productObj,true);
                    }else{
                      this.createCart('anonymous',productObj,false);
                    }
                  });
              }
          }
          }
          }
         }
    });
  }
  cookiebarverify(){
    const baseSite = this.singletonServ.catalogVersion;
    const allCookies: {} = this.cookieService.getAll();
    if(baseSite){
    if(baseSite.isoCode=='GB'){
      if(allCookies['cookiestored-gb']){
        document.getElementById("cookie").style.display="none";
      }
    }

     if(baseSite.isoCode=='US'){
      if(allCookies['cookiestored-US']){
        document.getElementById("cookie").style.display="none";
      }
    }

     if(baseSite.isoCode=='EU'){
      if(allCookies['cookiestored-eu']){
        document.getElementById("cookie").style.display="none";
      }
    }

     if(baseSite.isoCode=='DE'){
      if(allCookies['cookiestored-de']){
        document.getElementById("cookie").style.display="none";
      }
    }
  }
  }

  onToggleMenu(event){
      $('#mainBar').toggleClass("open-sidebar");
      $('#sidebar').toggleClass('change-sidebar');
  }
  sessionExpirez(){
    const baseSite =this.singletonServ.catalogVersion;
    if(baseSite.csAgent){
      this.singletonServ.sendMessage({invalidAgentToken:true});
    }
    if (isPlatformBrowser(this.platformId)) {
      this.removeBrowserSessionData("order");
      const _sessionNames=Object.keys(localStorage);
      if(_sessionNames.length){
          _sessionNames.map((obj)=>{
              this.removeBrowserSessionData(obj);
          });
      }
    }
    this.clearBrowserSessionData();
    this.singletonServ.cartObj=undefined;
    this.singletonServ.alarmTime=undefined;
    this.singletonServ.sessionStarts=false;
    this.setBrowserSessionData("prefered_lng", JSON.stringify(this.singletonServ.catalogVersion));
    for(let i=0; i<10000; i++){
      window.clearInterval(i);
    }
    this.singletonServ.sendMessage({clearCart:true});
    this.router.navigate(['store','global','sessionExpired']);
  }

  getBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      return this.singletonServ.getStoreData(key);
    }
  }
  setBrowserSessionData(key,data){
    if (isPlatformBrowser(this.platformId)) {
       this.singletonServ.setStoreData(key,data);
    }
  }

  clearBrowserSessionData(){
    if (isPlatformBrowser(this.platformId)) {
       this.singletonServ.clearItem();
    }
  }
  removeBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      this.singletonServ.removeItem(key);
    }
  }
    // method to check if Fresh Relevance popup arguments are receieved by angular component
 
    createNewUser(event) {
      // changes for password encryption
      let plaintText = event.detail.password;
       const encryptedData = CryptoJS.AES.encrypt(plaintText, this.parsedBase64Key, {
         mode: CryptoJS.mode.ECB,
         padding: CryptoJS.pad.Pkcs7,
       });
      
      const encryptedPassword = encryptedData.toString();
      const baseSite = this.singletonServ.catalogVersion;
       const _userBody = {
        uid: event.detail.email,
        password: encryptedPassword, 
        firstName: event.detail.first_name,
        lastName: event.detail.last_name,
        titleCode: event.detail.title,
        annexcloudOptin:event.detail.Loyalty_opt_in
       };
       if(baseSite){
        this.profileServ.generateToken(baseSite).subscribe(
          token => {
          const tokenId = token["access_token"]; 
          if(_userBody){
            if(tokenId){  
              this.profileServ.createUser(baseSite, _userBody, tokenId).subscribe(
                resp => {
                  console.log("response of user created", resp);
                  const obj = {
                    access_token: resp["access_token"],
                    email: event.detail.email,
                    token: token["access_token"]
                  };
                  this.setBrowserSessionData(baseSite.reg, JSON.stringify(obj));
                },
                err => {
                  if (err) {
                    if (err.error) {
                      if (err.error.errors) {
                        console.log("err.error.errors==>", err.error.errors);
                      }
                    }
                  }
               
                }
              );
            }
          }
      });
    }
  }
  
  }
