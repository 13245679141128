// AmplienceContent DElivery 2 API - Delivery keys for Redesign 2022
export const DeliveryKey = {
    "footer":{
        "US" : "Footer-US",
        "GB" : "Footer-GB",
        "EU" : "Footer-EU",
        "DE" : "Footer-DE"
    },   
    "footerRefactor":{
        "US" : "footer-refactor-US",
        "GB" : "footer-refactor-GB",
        "EU" : "footer-refactor-EU",
        "DE" : "footer-refactor-DE"
    },
    "headerLogo":{
        "US" : "headerLogo-US",
        "GB" : "headerLogo-GB",
        "EU" : "headerLogo-EU",
        "DE" : "headerLogo-DE"
    },
    "banner":{
        "US" : "header-greyBanner-US",
        "GB" : "header-greyBanner-GB",
        "EU" : "header-greyBanner-EU",
        "DE" : "header-greyBanner-DE"
    },
    "singleMsgBanner":{
        "US" : "singleMsgBanner-US",
        "GB" : "singleMsgBanner-GB",
        "EU" : "singleMsgBanner-EU",
        "DE" : "singleMsgBanner-DE"
    },
    "heroBanner":{
        "US" : "heroBanner-redesign2023-slot-US",
        "GB" : "heroBanner-redesign2023-slot-GB",
        "EU" : "heroBanner-redesign2023-slot-EU",
        "DE" : "heroBanner-redesign2023-slot-DE"
    },
    "twoMsgBanner":{
        "US" : "twoMsgBanner-Slot-US",
        "GB" : "twoMsgBanner-Slot-GB",
        "EU" : "twoMsgBanner-Slot-EU",
        "DE" : "twoMsgBanner-Slot-DE"
        },
    "additionalMsgBanner":{
        "US" : "additionalMsgBanner-Slot-US",
        "GB" : "additionalMsgBanner-Slot-GB",
        "EU" : "additionalMsgBanner-Slot-EU",
        "DE" : "additionalMsgBanner-Slot-DE"
    },    
    "cicleImageOverlayBanner":{
        "US" : "circleImageOverlayBanner-Slot-US-v1",
        "GB" : "circleImageOverlayBanner-Slot-GB-v1",
        "EU" : "circleImageOverlayBanner-Slot-EU-v1",
        "DE" : "circleImageOverlayBanner-Slot-DE-v1"
    },
    "esgHomePageBanner":{
        "US" : "esgHomePageBanner-Slot-US",
        "GB" : "esgHomePageBanner-Slot-GB",
        "EU" : "esgHomePageBanner-Slot-EU",
        "DE" : "esgHomePageBanner-Slot-DE" 
    },  
    "freshrelevanceHPbanner":{
        "US" : "freshrelevanceHP-Slot-US",
        "GB" : "freshrelevanceHP-Slot-GB",
        "EU" : "freshrelevanceHP-Slot-EU",
        "DE" : "freshrelevanceHP-Slot-DE" 
    },
    "deliveryInformation":{
        "US" : "delivery-information-Slot-US",
        "GB" : "delivery-information-Slot-GB",
        "EU" : "delivery-information-Slot-EU",
        "DE" : "delivery-information-Slot-DE" 
    },
    "secondaryNavigation":{
        "US" : "secondaryNavigation-Slot-US",
        "GB" : "secondaryNavigation-Slot-GB",
        "EU" : "secondaryNavigation-Slot-EU",
        "DE" : "secondaryNavigation-Slot-DE" 
    },
    "deliveryNonHazardous":{
        "US" : "delivery-non-hazardous-US",
        "GB" : "delivery-non-hazardous-GB",
        "EU" : "delivery-non-hazardous-EU",
        "DE" : "delivery-non-hazardous-DE" 
    },
    "deliveryHazardous":{
        "US" : "delivery-hazardous-US",
        "GB" : "delivery-hazardous-GB",
        "EU" : "delivery-hazardous-EU",
        "DE" : "delivery-hazardous-DE" 
    },
    "informationPage":{
        "US" : "information-landingPage-US",
        "GB" : "information-landingPage-GB",
        "EU" : "information-landingPage-EU",
        "DE" : "information-landingPage-DE" 
    },
    "termsPoliciesPage":{
        "US" : "terms-policies-US",
        "GB" : "terms-policies-new-GB",
        "EU" : "terms-policies-EU",
        "DE" : "terms-policies-DE" 
    },    
    "hotelLandingPage":{
        "US" : "hotel-landing-page-redesign-US",
        "GB" : "hotel-landing-page-redesign-GB",
        "EU" : "hotel-landing-page-redesign-EU",
        "DE" : "hotel-landing-page-redesign-DE"     
    },
    "hotelwhyMbPage":{
        "US" : "hotel-why-mb-page-redesign-US",
        "GB" : "hotel-why-mb-page-redesign-GB",
        "EU" : "hotel-why-mb-page-redesign-EU",
        "DE" : "hotel-why-mb-page-redesign-DE"     
    },
    "hotelLuxuryCollectionOnePage":{
        "US" : "hotel-luxury-collection-one-US",
        "GB" : "hotel-luxury-collection-one-GB",
        "EU" : "hotel-luxury-collection-one-EU",
        "DE" : "hotel-luxury-collection-one-DE"
    }, 
    "revivingRosemaryCollectionPageRefactor":{
        "US" : "reviving-rosemary-collection-refactor-US",
        "GB" : "reviving-rosemary-collection-refactor-GB",
        "EU" : "reviving-rosemary-collection-refactor-EU",
        "DE" : "reviving-rosemary-collection-refactor-DE"
    }, 
    "hotel1971collectionPageRefactor":{
        "US" : "hotel-1971-collection-refactor-US",
        "GB" : "hotel-1971-collection-refactor-GB",
        "EU" : "hotel-1971-collection-refactor-EU",
        "DE" : "hotel-1971-collection-refactor-DE"
    },
    "hotelLuxuryAccessoriesPageRefactor":{
        "US" : "hotel-luxury-accessories-refactor-US",
        "GB" : "hotel-luxury-accessories-refactor-GB",
        "EU" : "hotel-luxury-accessories-refactor-EU",
        "DE" : "hotel-luxury-accessories-refactor-DE"
    },
    "hotelTurndownCollectionPageRefactor":{
        "US" : "hotel-turndown-collection-refactor-US",
        "GB" : "hotel-turndown-collection-refactor-GB",
        "EU" : "hotel-turndown-collection-refactor-EU",
        "DE" : "hotel-turndown-collection-refactor-DE"
    },  
    "hotelNewCollectionPage":{
        "US" : "hotel-new-collection-US",
        "GB" : "hotel-new-collection-GB",
        "EU" : "hotel-new-collection-EU",
        "DE" : "hotel-new-collection-DE"
    },       
    "hotelAluminiumPage":{
        "US" : "luxury-collection-aluminium-tube-refactor-US",
        "GB" : "luxury-collection-aluminium-tube-refactor-GB",
        "EU" : "luxury-collection-aluminium-tube-refactor-EU",
        "DE" : "luxury-collection-aluminium-tube-refactor-DE"
    },
    "hotelLuxuryCollectionPageRefactor":{
        "US" : "luxury-collection-refactor-US",
        "GB" : "luxury-collection-refactor-GB",
        "EU" : "luxury-collection-refactor-EU",
        "DE" : "luxury-collection-refactor-DE"
    },
    "hotelSustainableDispensing":{
        "US" : "hotel-sustainable-dispensers-page-US",
        "GB" : "hotel-sustainable-dispensers-page-GB",
        "EU" : "hotel-sustainable-dispensers-page-EU",
        "DE" : "hotel-sustainable-dispensers-page-DE"     
    },
    "hotelLuxuryHotelCollectionsPage":{
        "US" : "hotel-luxury-hotel-collections-US",
        "GB" : "hotel-luxury-hotel-collections-GB",
        "EU" : "hotel-luxury-hotel-collections-EU",
        "DE" : "hotel-luxury-hotel-collections-DE"
    },
    "contact-us":{
        "US" : "new-contact-us-US",
        "GB" : "new-contact-us-GB",
        "EU" : "new-contact-us-EU",
        "DE" : "new-contact-us-DE" 
    },
    "faqs":{
        "US" : "faqs-US",
        "GB" : "faqs-new-GB",
        "EU" : "faqs-EU",
        "DE" : "faqs-DE" 
    },
    "rewardsPage": "rewardsLPNewLogo",
    "stories":{
        "US" : "Stories-Landing-Page-US",
        "GB" : "Stories-Landing-Page-GB",
        "EU" : "Stories-Landing-Page-EU",
        "DE" : "Stories-Landing-Page-DE" 
    },
    "sitemap":{
        "US" : "SiteMap-US",
        "GB" : "SiteMap-UK",
        "EU" : "SiteMap-EU",
        "DE" : "SiteMap-DE" 
    },
    "meet-the-perfumers-jacques-chabert":{
        "US" : "Stories-Perfumer-Jacques-US",
        "GB" : "Stories-Perfumer-Jacques-GB",
        "EU" : "Stories-Perfumer-Jacques-EU",
        "DE" : "Stories-Perfumer-Jacques-DE" 
    },
    "meet-the-perfumers-carla-chabert":{
        "US" : "Stories-Perfumer-Carla-US",
        "GB" : "Stories-Perfumer-Carla-GB",
        "EU" : "Stories-Perfumer-Carla-EU",
        "DE" : "Stories-Perfumer-Carla-DE" 
    },
    "meet-the-perfumers-maia-lernout":{
        "US" : "Stories-Perfumer-Maia-US",
        "GB" : "Stories-Perfumer-Maia-GB",
        "EU" : "Stories-Perfumer-Maia-EU",
        "DE" : "Stories-Perfumer-Maia-DE" 
    },
    "meet-the-perfumers-jerome-di-marino":{
        "US" : "Stories-Perfumer-Jerome-US",
        "GB" : "Stories-Perfumer-Jerome-GB",
        "EU" : "Stories-Perfumer-Jerome-EU",
        "DE" : "Stories-Perfumer-Jerome-DE" 
    },
    "meet-the-perfumers-julie-pluchet":{
        "US" : "Stories-Perfumer-Julie-US",
        "GB" : "Stories-Perfumer-Julie-GB",
        "EU" : "Stories-Perfumer-Julie-EU",
        "DE" : "Stories-Perfumer-Julie-DE" 
    },
    "terms-conditions": {
        "US" : "terms-conditions-US",
        "GB" : "terms-conditions-GB",
        "EU" : "terms-conditions-EU", 
    },
    "german-terms-conditions":{
        "DE" : "terms-conditions-DE"
    },
    "gift-terms-conditions": {
        "US" : "gift-terms-conditions-US",
        "GB" : "gift-terms-conditions-GB",
        "EU" : "gift-terms-conditions-EU",
        "DE" : "gift-terms-conditions-DE" 
    },
    "company-information": {
        "US" : "company-information-US",
        "GB" : "company-information-GB",
        "EU" : "company-information-EU"
    },  
    "german-impressum": {
        "DE" : "company-information-DE" 
    },
    "privacy-policy": {
        "US" : "privacy-policy-US",
        "GB" : "privacy-policy-GB",
        "EU" : "privacy-policy-EU"
    },
    "german-privacy-policy": {
        "DE" : "privacy-policy-DE" 
    },
    "trade-enquiries": {
        "GB" : "trade-enquiries-GB",
        "EU" : "trade-enquiries-EU",
        "DE" : "trade-enquiries-DE" ,
    } ,
    "trade-inquiries": {
        "US" : "trade-enquiries-US"
    } ,
    // delivery & Returns
    "delivery": {
        "GB" : "delivery-returns-UK",
        "EU" : "delivery-returns-Eu",
    } ,   
    "shipping": {
        "US" : "delivery-returns-US",
    } ,  
    "german-delivery": {
        "DE" : "delivery-returns-DE",
    } ,
    // delivery & Returns
    "returns-delivery": {
        "GB" : "delivery-returns-UK",
        "EU" : "delivery-returns-Eu",
    } ,   
    "returns-shipping": {
        "US" : "delivery-returns-US",
    } ,  
    "returns-german-delivery": {
        "DE" : "delivery-returns-DE",
    } ,  
    "corporateGifts":{
        "US" : "corporatePage-US",
        "GB" : "corporatePage-GB",
        "EU" : "corporatePage-EU",
        "DE" : "corporatePage-DE" 
    },  
    "specialOffers": {
        "US" : "specialOffers-US",
        "GB" : "specialOffers-UK",
        "EU" : "specialOffers-EU",
        "DE" : "specialOffers-DE" 
    },
    "bespokeHotelServices": {
        "US" : "bespoke-hotel-services-US",
        "GB" : "bespoke-hotel-services-GB",
        "EU" : "bespoke-hotel-services-EU",
        "DE" : "bespoke-hotel-services-DE"
    }, 
    "aboutUs":{
        "US" : "about-us-US",
        "GB" : "about-us-GB",
        "EU" : "about-us-EU",
        "DE" : "about-us-DE" 
    },
    "promo-terms-conditions": {
        "US" : "promo-terms-condition-US",
        "GB" : "promo-terms-condition-GB",
        "EU" : "promo-terms-condition-EU",
        "DE" : "promo-terms-condition-DE" 
    },
    "careersPage": {
        "US" : "careersPage-US",
        "GB" : "careersPage-GB",
        "EU" : "careersPage-EU",
        "DE" : "careersPage-DE" 
    },
    "getInTouchHotel": {        
        "US" : "hotel-get-in-touch-US",
        "GB" : "hotel-get-in-touch-GB",
        "EU" : "hotel-get-in-touch-EU",
        "DE" : "hotel-get-in-touch-DE"
    },  
    //Short Terms - Loyalty
    "short-terms": {
        "GB" : "mb-reward-club-short-tc-GB",
    },
    //Product Page - Loyalty
    "productLoyaltyPoints": {
        "GB" : "product-loyalty-points",
    },
    // Start of Stories Page Delivery keys   
        "travel":{
            "US" : "Stories-Travel-US",
            "GB" : "Stories-Travel-GB",
            "EU" : "Stories-Travel-EU",
            "DE" : "Stories-Travel-DE",
        },
        "lifestyle":{
            "US" : "Stories-Lifestyle-US",
            "GB" : "Stories-Lifestyle-GB",
            "EU" : "Stories-Lifestyle-EU",
            "DE" : "Stories-Lifestyle-DE",
        },
        "news":{
            "US" : "Stories-News-US",
            "GB" : "Stories-News-GB",
            "EU" : "Stories-News-EU",
            "DE" : "Stories-News-DE",
        },
        "fragrance":{
            "US" : "Stories-Fragrance-US",
            "GB" : "Stories-Fragrance-GB",
            "EU" : "Stories-Fragrance-EU",
            "DE" : "Stories-Fragrance-DE",
        },
        "perfumers":{
            "US" : "Stories-Perfumer-Category-US",
            "GB" : "Stories-Perfumer-Category-GB",
            "EU" : "Stories-Perfumer-Category-EU",
            "DE" : "Stories-Perfumer-Category-DE",
        },
        "lotte-new-york-palace-competition":{
            "US" : "Competition-Lotte-Hotel-US",
            "GB" : "Competition-Lotte-Hotel-GB"
        },
        "lotte-new-york-palace-competitionentered":{
            "US" : "Competition-Lotte-Hotel-Thkyou-US",
            "GB" : "Competition-Lotte-Hotel-Thkyou-GB"
        },
        "sustainabilityPage": {
            "US" : "Stories-Sustainability-US",
            "GB" : "Stories-Sustainability-GB",
            "EU" : "Stories-Sustainability-EU",
            "DE" : "Stories-Sustainability-DE" 
        },
        "home-spa-experience":{
            "US" : "Stories-Home-Spa-Experience-US",
            "GB" : "Stories-Home-Spa-Experience-GB",
            "EU" : "Stories-Home-Spa-Experience-EU",
            "DE" : "Stories-Home-Spa-Experience-DE" 
        },
        "debunking-beauty-myths":{
            "US" : "Stories-Debunking-Beauty-Myths-US",
            "GB" : "Stories-Debunking-Beauty-Myths-GB",
            "EU" : "Stories-Debunking-Beauty-Myths-EU",
            "DE" : "Stories-Debunking-Beauty-Myths-DE" 
        },
        "ambery-fragrance":{
            "US" : "Stories-Ambery-Fragrance-US",
            "GB" : "Stories-Ambery-Fragrance-GB",
            "EU" : "Stories-Ambery-Fragrance-EU",
            "DE" : "Stories-Ambery-Fragrance-DE" 
        }, 
        "healthy-hair-care-routine":{
            "US" : "Stories-2022-Hair-Care-Routine-US",
            "GB" : "Stories-2022-Hair-Care-Routine-GB",
            "EU" : "Stories-2022-Hair-Care-Routine-EU",
            "DE" : "Stories-2022-Hair-Care-Routine-DE" 
        },
        "spring-skincare-routine":{
            "US" : "Stories-2022-Spring-Care-Routine-US",
            "GB" : "Stories-2022-Spring-Care-Routine-GB",
            "EU" : "Stories-2022-Spring-Care-Routine-EU",
            "DE" : "Stories-2022-Spring-Care-Routine-DE" 
        },
        "effective-hand-care-routine":{
            "US" : "Stories-2022-Hand-Care-Routine-US",
            "GB" : "Stories-2022-Hand-Care-Routine-GB",
            "EU" : "Stories-2022-Hand-Care-Routine-EU",
            "DE" : "Stories-2022-Hand-Care-Routine-DE" 
        },
        "floral-fragrance-family":{
            "US" : "Stories-Floral-Fragrance-US",
            "GB" : "Stories-Floral-Fragrance-GB",
            "EU" : "Stories-Floral-Fragrance-EU",
            "DE" : "Stories-Floral-Fragrance-DE" 
        },
        "woody-fragrance-family":{
            "US" : "Stories-Woody-Fragrance-US",
            "GB" : "Stories-Woody-Fragrance-GB",
            "EU" : "Stories-Woody-Fragrance-EU",
            "DE" : "Stories-Woody-Fragrance-DE" 
        },
        "autumn-winter-fragrance-wardrobe":{
            "US" : "Stories-Autumn-Winter-Wardobe-US",
            "GB" : "Stories-Autumn-Winter-Wardobe-GB",
            "EU" : "Stories-Autumn-Winter-Wardobe-EU",
            "DE" : "Stories-Autumn-Winter-Wardobe-DE" 
        },
        "difference-between-eau-de-toilette-eau-de-parfum":{
            "US" : "Stories-EDT-vs-EDP-US",
            "GB" : "Stories-EDT-vs-EDP-GB",
            "EU" : "Stories-EDT-vs-EDP-EU",
            "DE" : "Stories-EDT-vs-EDP-DE" 
        },
        "online-perfume-shopping-guide":{
            "US" : "Stories-Online-Perfume-Guide-US",
            "GB" : "Stories-Online-Perfume-Guide-GB",
            "EU" : "Stories-Online-Perfume-Guide-EU",
            "DE" : "Stories-Online-Perfume-Guide-DE" 
        },
        "side-design-hotel-hamburg":{
            "US" : "Stories-Design-Hotel-Hamburg-US",
            "GB" : "Stories-Design-Hotel-Hamburg-GB",
            "EU" : "Stories-Design-Hotel-Hamburg-EU",
            "DE" : "Stories-Design-Hotel-Hamburg-DE" 
        },
        "sustainable-hotels":{
            "US" : "Stories-Sustainable-Hotels-US",
            "GB" : "Stories-Sustainable-Hotels-GB",
            "EU" : "Stories-Sustainable-Hotels-EU",
            "DE" : "Stories-Sustainable-Hotels-DE" 
        },
        "uk-hotel-guide":{
            "US" : "Stories-Travel-Guide-York-US",
            "GB" : "Stories-Travel-Guide-York-GB",
            "EU" : "Stories-Travel-Guide-York-EU",
            "DE" : "Stories-Travel-Guide-York-DE" 
        },
        "soul-soothing-hotel-spas":{
            "US" : "Stories-Soothing-Hotel-Spas-US",
            "GB" : "Stories-Soothing-Hotel-Spas-GB",
            "EU" : "Stories-Soothing-Hotel-Spas-EU",
            "DE" : "Stories-Soothing-Hotel-Spas-DE" 
        },       
        "past-present-packaging":{
            "US" : "Stories-Past-Present-Packaging-US",
            "GB" : "Stories-Past-Present-Packaging-GB",
            "EU" : "Stories-Past-Present-Packaging-EU",
            "DE" : "Stories-Past-Present-Packaging-DE" 
        },       
        "refillable-hand-programme":{
            "US" : "Stories-Refillable-Hand-Programme-US",
            "GB" : "Stories-Refillable-Hand-Programme-GB",
            "EU" : "Stories-Refillable-Hand-Programme-EU",
            "DE" : "Stories-Refillable-Hand-Programme-DE" 
        },       
        "bbc-film-esg":{
            "US" : "Stories-BBC-Film-ESG-US",
            "GB" : "Stories-BBC-Film-ESG-GB",
            "EU" : "Stories-BBC-Film-ESG-EU",
            "DE" : "Stories-BBC-Film-ESG-DE" 
        },       
        "molton-brown-history":{
            "US" : "Stories-Molton-Brown-History-US",
            "GB" : "Stories-Molton-Brown-History-GB",
            "EU" : "Stories-Molton-Brown-History-EU",
            "DE" : "Stories-Molton-Brown-History-DE" 
        },       
        "gift-wrapping-services":{
            "US" : "Stories-Gift-Wrapping-Services-US",
            "GB" : "Stories-Gift-Wrapping-Services-GB",
            "EU" : "Stories-Gift-Wrapping-Services-EU",
            "DE" : "Stories-Gift-Wrapping-Services-DE" 
        },       
        "citrus-fragrance-family":{
            "US" : "Stories-Citrus-Fragrance-Family-US",
            "GB" : "Stories-Citrus-Fragrance-Family-GB",
            "EU" : "Stories-Citrus-Fragrance-Family-EU",
            "DE" : "Stories-Citrus-Fragrance-Family-DE" 
        },       
        "summer-fragrance-wardrobe":{
            "US" : "Stories-Summer-Fragrance-Wardrobe-US",
            "GB" : "Stories-Summer-Fragrance-Wardrobe-GB",
            "EU" : "Stories-Summer-Fragrance-Wardrobe-EU",
            "DE" : "Stories-Summer-Fragrance-Wardrobe-DE" 
        },       
        "summer-getaway-beauty-essentials":{
            "US" : "Stories-Summer-Beauty-Getaway-US",
            "GB" : "Stories-Summer-Beauty-Getaway-GB",
            "EU" : "Stories-Summer-Beauty-Getaway-EU",
            "DE" : "Stories-Summer-Beauty-Getaway-US" 
        },       
        "sustainable-travel-tips-lostwithjess":{
            "US" : "Stories-Travel-Tips-With-Jess-US",
            "GB" : "Stories-Travel-Tips-With-Jess-GB",
            "EU" : "Stories-Travel-Tips-With-Jess-EU",
            "DE" : "Stories-Travel-Tips-With-Jess-DE" 
        }, 
        "bathroom-trends-2023":{
            "US" : "Stories-Bathroom-Trends-2023-US",
            "GB" : "Stories-Bathroom-Trends-2023-GB",
            "EU" : "Stories-Bathroom-Trends-2023-EU",
            "DE" : "Stories-Bathroom-Trends-2023-DE" 
        },  
        "wind-down-evening-routine":{
            "US" : "Stories-Wind-Down-Evening-Routine-US",
            "GB" : "Stories-Wind-Down-Evening-Routine-GB",
            "EU" : "Stories-Wind-Down-Evening-Routine-EU",
            "DE" : "Stories-Wind-Down-Evening-Routine-DE" 
        }, 
        "lotte-new-york-palace":{
            "US" : "Stories-Lotte-New-York-Palace-US",
            "GB" : "Stories-Lotte-New-York-Palace-GB",
        },       
        "meet-the-perfumers":{
            "US" : "Stories-Perfumer-Category-US",
            "GB" : "Stories-Perfumer-Category-GB",
            "EU" : "Stories-Perfumer-Category-EU",
            "DE" : "Stories-Perfumer-Category-DE" 
        },
        "luxury-partner-hotel-1":{
            "US" : "Stories-Partner-Hotel-1-US",
            "GB" : "Stories-Partner-Hotel-1-GB",
            "EU" : "Stories-Partner-Hotel-1-EU",
        },
        "luxury-partner-hotel-2":{
            "US" : "Stories-Partner-Hotel-2-US",
            "GB" : "Stories-Partner-Hotel-2-GB",
            "EU" : "Stories-Partner-Hotel-2-EU",
        },
        "luxury-partner-hotel-3":{
            "US" : "Stories-Partner-Hotel-3-US",
            "GB" : "Stories-Partner-Hotel-3-GB",
            "EU" : "Stories-Partner-Hotel-3-EU",
        },
        "luxury-partner-hotel-4":{
            "US" : "Stories-Partner-Hotel-4-US",
            "GB" : "Stories-Partner-Hotel-4-GB",
            "EU" : "Stories-Partner-Hotel-4-EU",
        },
        "luxury-partner-hotel-5":{
            "US" : "Stories-Partner-Hotel-5-US",
            "GB" : "Stories-Partner-Hotel-5-GB",
            "EU" : "Stories-Partner-Hotel-5-EU",
        },
        "bring-spring-in-with-influencer":{
            "GB" : "Stories-Bring-Spring-In-GB",
        },
        "partner-lick":{
            "GB" : "Stories-Partner-Lick-GB",
        },
        // End of Stories Page Delivery keys
        //Start of Generic urls
        "partner-hotel-competition-1":{
            "US" : "PartnerHotel-Competition1-US",
            "GB" : "PartnerHotel-Competition1-GB",
            "EU" : "PartnerHotel-Competition1-EU"
        },
        "partner-hotel-competition-2":{
            "US" : "PartnerHotel-Competition2-US",
            "GB" : "PartnerHotel-Competition2-GB",
            "EU" : "PartnerHotel-Competition2-EU"
        },
        "partner-hotel-competition-1entered":{
            "US" : "HotelPartner-Competition1-Thkyou-US",
            "GB" : "HotelPartner-Competition1-Thkyou-GB",
            "EU" : "HotelPartner-Competition1-Thkyou-EU"
        },
        "partner-hotel-competition-2entered":{
            "US" : "HotelPartner-Competition2-Thkyou-US",
            "GB" : "HotelPartner-Competition2-Thkyou-GB",
            "EU" : "HotelPartner-Competition2-Thkyou-EU"
        }, 
        "partner-competition-1":{
            "US" : "Generic-Competition1-US",
            "GB" : "Generic-Competition1-GB",
            "EU" : "Generic-Competition1-EU",
            "DE" : "Generic-Competition1-DE"
        },
        "partner-competition-1entered":{
            "US" : "Generic-Competition1-Thkyou-US",
            "GB" : "Generic-Competition1-Thkyou-GB",
            "EU" : "Generic-Competition1-Thkyou-EU",
            "DE" : "Generic-Competition1-Thkyou-DE"
        },
        "partner-competition-2":{
            "US" : "Generic-Competition2-US",
            "GB" : "Generic-Competition2-GB",
            "EU" : "Generic-Competition2-EU",
            "DE" : "Generic-Competition2-DE"
        },
        "partner-competition-2entered":{
            "US" : "Generic-Competition2-Thkyou-US",
            "GB" : "Generic-Competition2-Thkyou-GB",
            "EU" : "Generic-Competition2-Thkyou-EU",
            "DE" : "Generic-Competition2-Thkyou-DE"
        },
        "editorial-1":{
            "US" : "Generic-Editorial-1-US",
            "GB" : "Generic-Editorial-1-GB",
            "EU" : "Generic-Editorial-1-EU",
            "DE" : "Generic-Editorial-1-DE"
        },
        "editorial-2":{
            "US" : "Generic-Editorial-2-US",
            "GB" : "Generic-Editorial-2-GB",
            "EU" : "Generic-Editorial-2-EU",
            "DE" : "Generic-Editorial-2-DE"
        },
        "partner-1":{
            "GB" : "Generic-Partnership-GB",
        },
        //end of generic urls

        "joinGuest":{
            "US" : "JoinOurGuestList-US",
            "GB" : "JoinOurGuestList-GB",
            "EU" : "JoinOurGuestList-EU",
            "DE" : "JoinOurGuestList-DE"
        },    
        "affiliatePage": {
            "US" : "affiliatePage-US",
            "GB" : "affiliatePage-GB",
            "EU" : "affiliatePage-EU",
            "DE" : "affiliatePage-DE" 
        },
        "heritagepage": {
            "US" : "heritagePage-US",
            "GB" : "heritagePage-GB",
            "EU" : "heritagePage-EU",
            "DE" : "heritagePage-DE" 
        },      
        "store-events":{
            "US" : "storeEvents-US",
            "GB" : "storeEvents-GB",
            "EU" : "storeEvents-EU",
            "DE" : "storeEvents-DE" 
        },
        "storeFinder" : {
            "US" : "storeFinder-US",
            "GB" : "storeFinder-GB",
            "EU" : "storeFinder-EU",
            "DE" : "storeFinder-DE" 
        },
        "giftBuilder" : {
            "GB" : "giftBuilder-UK"
        },
        "pick&Mix" : {
            "GB" : "picknmix-GB",
            "EU" : "picknmix-EU",
            "DE" : "picknmix-DE"
        },
        "storyhierarchy" : {
            "GB" : "17980a6c-e4e6-4449-a89d-738c73785fef",
            "EU" : "35cf44b7-bd29-4986-addd-5f9a8fde4d1e",
            "DE" : "c249fa01-ca82-491f-b2d8-2c84255c7b33",
            "US" : "6f109c3b-0af9-498d-99c5-b003e9aa8ba6"
        },
        "competitionhierarchy" : {
            "GB" : "52810c2f-6792-484f-a35a-8288f8eb34ed",
            "EU" : "924ee89e-683c-4e7b-b6c7-89fa61cb12a4",
            "DE" : "c99b0f28-b27b-4534-b821-63a6b0f668d4",
            "US" : "fe82f7aa-9acb-4ef8-81a7-14631408f5a1"
        },
        "CharitySupportText" : {
            "GB" : "CharitySupportText-GB",
            "EU" : "CharitySupportText-EU",
            "DE" : "CharitySupportText-DE",
            "US" : "CharitySupportText-US"
        },
        "BatchCodeModal" : {
            "GB" : "BatchCodeModal-GB",
            "EU" : "BatchCodeModal-EU",
            "DE" : "BatchCodeModal-DE",
            "US" : "BatchCodeModal-US"
        },
        "subscriptionPage":{
            "US" : "subscriptionLandingPage-US",
            "GB" : "subscriptionLandingPage-GB",
            "EU" : "subscriptionLandingPage-EU",
            "DE" : "subscriptionLandingPage-DE" 
        }, 
        "subscription-terms-conditions": {
            "GB" : "Subscription-terms-conditions-GB",
        },
        "ArtistsofNoteHub":{
            "US" : "ArtistsofNoteHub-US",
            "GB" : "ArtistsofNoteHub-GB",
            "EU" : "ArtistsofNoteHub-EU",
            "DE" : "ArtistsofNoteHub-DE" 
        },
        "ChristmasHub":{
            "US" : "ChristmasHub-US",
            "GB" : "ChristmasHub-GB",
            "EU" : "ChristmasHub-EU",
            "DE" : "ChristmasHub-DE" 
        },
        "artistsofNoteHierarchy" : {
            "US" : "af1ca089-6a08-42c4-b7d5-a1f7eb05a39d",
            "GB" : "e8036375-44e1-4a1c-abe8-55b9e6b1f172",
            "EU" : "30ede252-6343-48b9-9415-f0c40546ec66",
            "DE" : "bf7b4834-82ce-4b7f-a331-af0c9391700e"
        },
        "waitlistHierarchy" : {
            "US" : "89a64391-b3e1-461e-879a-94b0f1dd5181",
            "GB" : "f39768e2-58fd-437c-b53a-485f06a1e9f6",
            "EU" : "775587db-0b8e-4e3c-af40-0cca126a7f3a",
            "DE" : "b544091f-9a8a-4c66-80e8-88b95ef116cc"
        },
        "christmasHierarchy" : {
            "US" : "7356ad1f-3c74-40c7-b347-204af1c7cb78",
            "GB" : "f9d6fea2-38aa-40f7-99a2-11a6fd6be47f",
            "EU" : "7c753188-9f50-46eb-82aa-1124bea1c333",
            "DE" : "5406ec3b-f167-474c-a97b-847242d23957"
        }
}