import {
  Inject,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
  Renderer2,
  HostListener,
  RendererFactory2
} from "@angular/core";
import { Location, DOCUMENT } from "@angular/common";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import * as _ from "lodash";
import { CategoryComponentService } from "./categorylanding-page.service";
import { PagerService } from "../services/pager.service";
import { SingletonService } from "../services/singleton.service";
import {MetaService} from "../services/meta.service";
import { Subscription } from "rxjs";
import { GtmMethodService } from '../services/gtmmethods.service';
import { SEOService } from "../services/seo.service";
import { Meta, Title } from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import { AmplienceService } from 'src/app/services/amplience.service';
import md from 'markdown-it';
import { filter } from "rxjs/operators";
import { WebExtendService } from "src/app/services/webExtend.service";

declare var AmpCa: any;
declare const $: any;
declare var window:any;
@Component({
  selector: "app-categorylanding-page",
  templateUrl: "./categorylanding-page.component.html",
  styleUrls: ["./categorylanding-page.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CategorylandingPageComponent
  implements OnInit, AfterViewInit, OnDestroy {
    sortByValue:string='';
    contryUrls = environment.alternateURLs;
    enviromentSpecific:any=environment;
    previousUrl: any;
    currentUrl: any;
    nextLoadedProdPosition: any;
    pageGiftBuilder: boolean = false;
    @HostListener("window:scroll", ["jQueryevent"])
    windowScroll(event) {
        const that=this;
        that.setProductFeeds();
    }
    setProductFeeds(){
      const that=this;
      const baseSite = this.singletonServ.catalogVersion;
      if(baseSite){
      $(window).scroll(function(event) {
        event.stopPropagation(); 
        const windowBottom = $(this).scrollTop() + $(this).innerHeight();
        if(that.freshRelevanceSpecific){
     
        } else{
              const _dataList :any=  $('.gtm-price-target').map(function(item,i){
              const objectBottom = $(this).offset().top +$('.gtm-price-target').innerHeight()*2;
               if(objectBottom<windowBottom){
                const _getFeed=that.constructPLPFeed($(this)[0].dataset.code);
                if(_getFeed){
                      return _getFeed
                 }
               }
             });
            if(_dataList.length !=0){
                if(that.feedList){
                    const _result= _.differenceWith(_dataList, that.feedList, _.isEqual);     
                     if(_result.length !=0){
                          that.gtmServe.gtmProductListPages(baseSite.currencyCode,_result);
                          that.feedList=_dataList;
                      }
                    }else{                      
                      const _primarylist= _.differenceWith(_dataList, that.feedList, _.isEqual);
                      that.feedList=_primarylist;
                      that.gtmServe.gtmProductListPages(baseSite.currencyCode,_primarylist);
                    }
            }
        }
      });
    }
    }
  @ViewChild("facetTag") facetValue: ElementRef;
  @ViewChild("parentCategory") parentCategory: ElementRef;
  categoryLandingSubscription:Subscription;
  catalogSpecific: boolean;
  navigationSubscription: any;
  searchPrdId: string;
  breadcrumb: any = [];
  routeParams: any;
  totalProducts: number;
  pager: any = {};
  pagedItems: any[];
  displayGrid: boolean;
  categorybanner: any;
  paths: string;
  freshRelevanceSpecific: boolean;
  viewAllProducts: boolean;
  searchCatId: string;
  activeProducts: boolean;
  catId: string;
  pageSize: number = 12;
  pageLoad: boolean;
  pagination: any;
  categoryResponse: any;
  facetResponse: any;
  checkList: boolean;
  openFacet: boolean;
  menuData: any;
  productreview:any;
  productreviewcount:any;
  reviewCount:any;
  feedList:Array<any>;
  outofstock:boolean;
  outofstockMessage:any;
  switchPageType:string;
  frSiteCode:string;
  products: any;
  loadprodarray=[];
  enableBestSeller: boolean;
  showShopCategory: boolean;
  viewallflag: any;
  secNavSlotid: any;
  secNavContent: any;
  secNavItems: any;
  previewId: any;
  private markdown;
  contentBanner : any;
  isEmarsysEnabled: boolean;
  isTemplateNameLoaded: boolean = false;
  templateName: any;
  competitionPage: any;
  competitionBackground: any;
  backgroundImage: any;
  thankYouPage: any;

  constructor(
    @Inject(DOCUMENT) public dom,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public singletonServ: SingletonService,
    private titleService: Title,    
    public mbPagerService: PagerService,
    public categoryServ: CategoryComponentService,
    public metaService:MetaService,
    public renderer: Renderer2,
    public gtmServe: GtmMethodService,
    private seoService: SEOService,
    private rendererFactory: RendererFactory2,
    private meta: Meta,
    public ampService: AmplienceService,
    public webExtend:WebExtendService

  ) {
    this.paths = "";
    this.catalogSpecific = false;
    this.displayGrid = true;
    this.viewAllProducts = false;
    this.pageLoad = false;
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
    this.markdown=md({html: true});

    this.route.queryParams.subscribe(params => {  
      this.previewId = params['previewId'];  
  });
  }

  constructPLPFeed(code){
    const _feedData=_.find(this.pagedItems,(obj)=>{
      return obj.code==code;
    });
    if(_feedData){
      const _feedPrice=(_feedData.price)?_feedData.price.value:'';
      let saleprice;
      if(_feedData.originalPrice){
        saleprice=_feedData.price.value
      } else {
        saleprice=''
      }
      const pageUrlCat = this.retireveCatpath(_feedData);
      const maincat=pageUrlCat[0].split('-').join(' & ');
      const _mainCat= maincat.toUpperCase();
      const subcat=pageUrlCat[1].split('-').join('');

      let _subCatg;
      if(pageUrlCat[1]=="bath-body-gift-sets" || pageUrlCat[1]=="body-lotion-cream" || pageUrlCat[1]=="bath-shower-gel"){
        if(pageUrlCat[1]=="bath-body-gift-sets"){
         let urlsplit:any=pageUrlCat[1].split("-")
         let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]+" "+urlsplit[3]
         _subCatg=subcat.toUpperCase()
        }
        else if(pageUrlCat[1]=="body-lotion-cream"){
         let urlsplit:any=pageUrlCat[1].split("-")
         let subcat=urlsplit[0]+" "+urlsplit[1]+" & "+urlsplit[2]
         _subCatg=subcat.toUpperCase()
        }
        else if(pageUrlCat[1]=="bath-shower-gel"){
         let urlsplit:any=pageUrlCat[1].split("-")
         let subcat=urlsplit[0]+" & "+urlsplit[1]+" "+urlsplit[2]
         _subCatg=subcat.toUpperCase()
        }

      }
      else{
       let subcat=pageUrlCat[1].split("-").join(" ");
       _subCatg=subcat.toUpperCase();
      }
      const _size=(_feedData.size)?_feedData.size:'';
      const _formattedsize=_size.replace("&#44",",");
      const _catDisplayName= (_feedData.category)?_feedData.category.categoryDisplayName:_subCatg;
      const _categoryList =_catDisplayName.toUpperCase();
      const reviewsproduct=(_feedData.stock)?_feedData.stock["stockLevelStatus"]:'outOfStock';
      if(reviewsproduct=="inStock"){
        this.productreview="True";
      } else{
        this.productreview="False";
      }
      if(_feedData.productAverageRating){
        this.productreviewcount=_feedData.productAverageRating;
        this.reviewCount=_feedData.reviewCount;
      }
      else{
        this.productreviewcount=0;
        this.reviewCount=0;
      }
      const _item={
        'name': _feedData.productDisplayName,      
        'id': _feedData.code,
        'price': '',
        'brand': 'Molton Brown',
        'category': _subCatg,
        'list': _mainCat+" - "+_subCatg,
        'variant': (_feedData.variantProduct)?(_feedData.variantProduct):'',
        'position': _feedData.id,
        'dimension3': (_formattedsize)?_formattedsize:"",
        'dimension4': this.productreviewcount,
        'dimension5': this.reviewCount,
        'dimension6': this.productreview,
        'dimension12':  this.productreview,    
        'dimension13': (_feedData.stock["stockLevel"])?_feedData.stock["stockLevel"]:'',
        'dimension14': (_feedData.productEdition)?_feedData.productEdition:'',
        'metric2': (saleprice).toString()
      }
      if(_feedData.originalPrice){
        const originalPrice= _feedData.originalPrice;
        const _originalPrice = originalPrice.match(/[\d\.]+/);
        if(_originalPrice){
          _item['price']=_originalPrice[0];
        }else{
          _item['price']=originalPrice;
        }
        }else{
          _item['price']=_feedPrice;
        }
        _item['price'] =(_item['price']).toString();
      return _item;
    }
  }

  fetchCatalogProducts(result) {
    this.navigationSubscription = this.route.params.subscribe(params => {
      this.routeParams = params;
      if(params.itemid){
       this.switchPageType='productDetailCheck';
       this.templateName = undefined;
       this.isTemplateNameLoaded = true;
      }else  if (params.categoryId) {
          const queryStatus = this.route.snapshot.queryParamMap.get("viewAllCat");
          const prdId = "/search?query=:relevance:allCategories:" + params.categoryId;
          this.catId = params.categoryId;
          this.searchCatId = params.categoryId;
          this.searchPrdId = prdId;
          if (queryStatus) {
            this.catalogSpecific = true;
            this.freshRelevanceSpecific = false;
            const prdId = "/search?query=:relevance:allCategories:" + params.categoryId;
            this.getCategoryData(prdId, true, this.pageSize, result.enableBestSeller ? 'bestSeller' : '');
          } else {
            this.constructRootCatProducts(result,prdId);
          }
      } else if (params.productid) {
        let prdId = "/search?query=:relevance:allCategories:" + params.productid;
        this.catId = params.productid;
        this.searchPrdId = prdId;
        this.getCategoryData(prdId, true, this.pageSize, result.enableBestSeller ? 'bestSeller' : '');
      }
      if (params.categoryname) {
        this.catalogSpecific = true;
      }
      this.templateName = undefined;
      this.isTemplateNameLoaded = true;
    });
  }
  retireveCatpath(dataurl){
    if(dataurl.category){
      const _url= dataurl.category.url.slice(1).replace("/c/", "/");
      return _url.split("/");
     }else{
      const _url= dataurl.url.slice(1).replace("/p/", "/");
      return _url.split("/");
     }
    }
  constructCatBanner(_catData) {
    const that=this;
    if (_catData) {
      this.showShopCategory = false;
      this.navigationSubscription = this.route.params.subscribe(params => {
        if(params.itemid){
          this.switchPageType='productDetailCheck';   
         }else if (params.categoryId) {
          that.setBannerContent(_catData, params.categoryId);
          if(this.isEmarsysEnabled) {
            this.webExtend.webExtendCategoryLandingPage(params.categoryId);
          }
         // this.location.replaceState(this.router.routerState.snapshot.url.toLowerCase());
        }else if (params.productid) {
          that.setBannerContent(_catData, params.productid);
         // this.location.replaceState(this.router.routerState.snapshot.url.toLowerCase());
          this.showShopCategory = true;
        }
        this.isTemplateNameLoaded = true;
        this.templateName = undefined;
  
      });

    }
  }
  constructRootCatProducts(category,prdId){
    if(category.isFRProductResponse) {      
          const queryStatus = this.route.snapshot.queryParamMap.get("viewAllCat");
          if(queryStatus){
            this.catalogSpecific = true;
            this.freshRelevanceSpecific = false;
            this.getCategoryData(prdId, true, this.pageSize);
          }else{
            this.freshRelevanceSpecific = true;
            this.getParentCategory(prdId,true, this.pageSize);    
          }          
          this.pageLoad = true;
    } else {
          this.catalogSpecific = true;
          this.freshRelevanceSpecific = false;
          this.getCategoryData(prdId, true, this.pageSize,this.enableBestSeller ? 'bestSeller' : '');
    }
  }
  setBannerContent(_catData, _catId){
    console.log(_catData)
    console.log(_catId)
    const _breadCrumb = this.findCat(_catData, _catId);     
    console.log(_breadCrumb)    
    if (_breadCrumb) {
          if(_breadCrumb[1]){
            if(!_breadCrumb[1].isL3){
              _breadCrumb.splice(1, 1);
              this.switchPageType='categoryCheck';
              this.breadcrumb = _breadCrumb;
            } else {
              this.switchPageType='categoryCheck';
              this.breadcrumb = _breadCrumb;
            }
        } else {
          this.switchPageType='categoryCheck';
        }
    }else{
      this.switchPageType='productDetailCheck';
      this.isTemplateNameLoaded = true;
      this.templateName = undefined;
    }
    for (let obj of _catData) {
      const result = this.getCatalogtree(obj, _catId);
      if (result) {
        if(result.amplienceDeliveryKey){
          this.ampService.getContentByKey(result.amplienceDeliveryKey).subscribe(resp=>{
            console.log('getContentByKey***********',resp)
            if(this.isCompetitionEntryOrThankyou(resp["content"] && resp["content"]._meta.schema)){
              this.competitionPage = resp["content"];
              this.templateName = this.competitionPage._meta.schema;
              if (this.competitionPage.description && this.competitionPage.description.text) this.competitionPage.description.text = this.markdown.render(this.competitionPage.description.text);
              this.backgroundImage = '//' + "media.moltonbrown.co.uk" + '/i/' + this.competitionPage.image.endpoint + '/' + this.competitionPage.image.name;
            }
          },(error:any)=>{
            console.log('error',error);
            this.templateName = undefined;
          },()=>{
            this.isTemplateNameLoaded = true;
          })
        }else{
          this.isTemplateNameLoaded = true;
        }
        this.titleService.setTitle(result.seoTitle);
        this.categorybanner = result;
        this.enableBestSeller = result.enableBestSeller; 
        const _resutUrl=result.url.replace("/c/", "/");
        const _baseSite = this.singletonServ;  
        const pageType = 'Category Landing Page';
        this.gtmServe.gtmPageCategorisation(_baseSite,pageType);
        if( result.ampCategoryTemplateId ) {
          this.switchPageType='staticpage';
          this.templateName = undefined;
          this.isTemplateNameLoaded = true;
        } else if(result.isFRProductResponse){ // to be removed as main category is not freshreleavance
            const queryStatus = this.route.snapshot.queryParamMap.get("viewAllCat");
          if(queryStatus){
            this.catalogSpecific = true;
            this.freshRelevanceSpecific = false;
            this.fetchCatalogProducts(result);
          }else{
            const prdId = "/search?query=:relevance:allCategories:" + result.id;
            this.freshRelevanceSpecific = true;
            this.getParentCategory(prdId,true, this.pageSize);   
          }
        } else{
          this.fetchCatalogProducts(result);
        }
        break;
      }
    }
  }
  ngOnInit() {   
    const baseSite = this.singletonServ.catalogVersion;
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: any) => {
    this.previousUrl= this.currentUrl; 
    this.currentUrl = event.url;
    if(this.previousUrl == '/checkout/mbOrderConfirmResponse' || this.previousUrl == '/checkout/login') {
      window.location.reload();
    }
  });
    if(this.singletonServ.loggedInPageLoad){
      this.singletonServ.loggedInPageLoad = false;
      window.location.reload();
    }
    const message = this.singletonServ.menudata;
    this.secNavSlotid = message;
    if (message) {
      this.constructCatBanner(message);
    }
    this.metaService.createCanonicalURL();   
    if(baseSite){
     this.frSiteCode=(baseSite.lngCode=='en')?'uk':baseSite.lngCode; 
    }
    this.singletonServ.getCatalogData().subscribe(resp => {
      this.isEmarsysEnabled = (resp["enableEmarsysFlow"]);
    },
      error => {}
    );
  }
  onUpdateProduct(event){
    this.outofstock=false;
    this.outofstockMessage=undefined;
  }
  ngAfterViewInit() {
    const that=this;
    this.singletonServ.getMessage().subscribe(message => {
      if (message.catgories) {
        this.menuData = message.catgories;
        this.constructCatBanner(message.catgories);
      } else if(message.outofStock){
        that.outofstock=true;
        that.outofstockMessage=message.outofStock.message;
      } else if(message.inStock){
        that.outofstock=false;
        that.outofstockMessage='';
      }
    });
    if (this.freshRelevanceSpecific) {
      let _element = this.dom.querySelectorAll("#frProduct");
      this.totalProducts = _element.length;
    }
    this.route.params.subscribe(params => {
      this.routeParams = params;
    });  
  }
  getCatalogtree(obj, targetId) {
    if(obj.id ){
    if (obj.id.toLowerCase() === targetId.toLowerCase()) {
      return obj;
    }
  }
    if (obj.subcategories) {
      for (let item of obj.subcategories) {
        const check = this.getCatalogtree(item, targetId);
        if (check) {
          return check;
        }
      }
    }
    return null;
  }
  findCat(array, id) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if(array[i].id){
          if (array[i].id.toLowerCase() == id.toLowerCase()) {
            console.log(array[i])
            return [array[i]];
          }
        }
        if(array[i]){
        if(array[i].subcategories){
        let a = this.findCat(array[i].subcategories, id);
        if (a != null) {
          a.unshift(array[i]);
          return a;
        }
       }
       }
      }
    }
    return null;
  }
  /* category level call */
  onviewAllClick(event) {
    this.freshRelevanceSpecific = false;
    let prdId = "/search?query=:relevance:allCategories:" + this.catId;
    const pageSize = 123;
    this.catalogSpecific = true;
    this.pager = false;
    this.viewAllProducts = true;
    this.singletonServ.scrollToTarget('#moltonbrown');
    this.getCategoryData(prdId, true, pageSize, this.enableBestSeller ? 'bestSeller' : '');
  }
  fetchProductNextperPage(data: any) {
    const status = data.status;
    this.singletonServ.scrollToTarget('#moltonbrown');
    if (!status) {
      let page = this.pagination.currentPage + 1;
      let prdId = "/search?query=:relevance:allCategories:" + this.catId;
      const id = prdId + this.paths + "&currentPage=" + page;
      this.getCategoryData(id, false, this.pageSize, this.enableBestSeller ? 'bestSeller' : '');
    } else {
      const _pageNumber = this.pagination.currentPage - 1;
      let prdId = "/search?query=:relevance:allCategories:" + this.catId;
      const id = prdId + this.paths + "&currentPage=" + _pageNumber;
      this.getCategoryData(id, false, this.pageSize,this.enableBestSeller ? 'bestSeller' : '');
    }
  }
  fetchProductperPage(data) {
    this.pageLoad = false;
    this.viewAllProducts = false;
    const pgNo = data["pageId"] - 1;
    const prdId = "/search?query=:relevance:allCategories:" + this.catId;
    const id = prdId + this.paths + "&currentPage=" + pgNo;
    this.singletonServ.scrollToTarget('#moltonbrown');
    this.getCategoryData(id, false, this.pageSize,this.enableBestSeller ? 'bestSeller' : '');
  }
  onViewAllProducts() {
    this.freshRelevanceSpecific = false;
    this.catalogSpecific = true;
    const productname = this.routeParams.productname;
    const categoryId = this.routeParams.categoryId;
    this.router.navigate(["store", productname, categoryId], {
      queryParams: { viewAllCat: true },
      queryParamsHandling: "merge"
    });
  
  }

  swapproducts(products, oldIndex, newIndex) {
    var temp = products[oldIndex];
    products[oldIndex] = products[newIndex];
    products[newIndex] = temp;
  }
  onShowFirstPage() {
    this.singletonServ.scrollToTarget('#moltonbrown');
    this.pager = true;
    this.viewAllProducts = false;
    this.freshRelevanceSpecific = false;
    const prdId = "/search?query=:relevance:allCategories:" + this.catId;
    const pageSize = 12;
    this.getCategoryData(prdId, true, pageSize, this.enableBestSeller ? 'bestSeller' : '');
  }
  onCheckRefineFacet() {
    this.openFacet = !this.openFacet;
  }
  onRefetchProducts(data) {
    const prdId = "/search?query=:relevance:allCategories:" + this.catId;
    if (data.id.length == 0) {
      this.checkList = false;
    } else {
      this.checkList = true;
    }
    this.paths = data.id;
    this.searchPrdId = prdId + this.paths;
    this.getCategoryData(this.searchPrdId, false, 12, this.enableBestSeller && !this.sortByValue ? 'bestSeller' : '');
  }
  onClearAll(data) {
    this.checkList=false;
    //this.singletonServ.scrollToTarget('#rich_cart');
    this.products=[];
    this.pager = true;
    this.viewAllProducts = false;
    this.freshRelevanceSpecific = false;
    const prdId = "/search?query=:relevance:allCategories:" + this.catId;
    const pageSize = 12;
    this.paths='';
    if(data && !data.isFilter){
    let radioButtonArray = document.getElementsByName('item-radio');
    for (var i=0; i<radioButtonArray.length; i++)
    {
    if(radioButtonArray[i]['checked'])
    {
    radioButtonArray[i]['checked'] = false;
    }
    }
    this.sortByValue="";
  }
    this.getCategoryData(prdId, true, pageSize, this.enableBestSeller ? 'bestSeller' : '');
  }

  onUpdateFilterData(data) {}
  updateFilterData(event, valueData, i) {}
  onMbFilterByClick() {}
  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if(this.categoryLandingSubscription){
    this.categoryLandingSubscription.unsubscribe();
   } 
  }

  sortByChange(data) {  
    this.pageLoad = false;  
    let prdId = "/search?query=:relevance:allCategories:" + this.catId;
    if(data.value=="newIn"){
      this.sortByValue='newIn';
      prdId = "/search?query=:relevance:allCategories:" + this.catId+":recentlyAdded:true";
      this.getCategoryData(prdId, true, this.pageSize,'newIn');
    }else if(data.value == "bestSeller"){
      this.sortByValue='bestSeller';
      this.getCategoryData(prdId, true, this.pageSize,'bestSeller');
    }else if(data.value=="price:ascending"){
      this.sortByValue='price-asc';
      this.getCategoryData(prdId, true, this.pageSize,'price-asc');
    }else if(data.value=="price:descending"){
      this.sortByValue='price-desc';
      this.getCategoryData(prdId, true, this.pageSize,'price-desc');
    }else if(data.value==""){
      this.sortByValue='';
      this.getCategoryData(prdId, true, this.pageSize,this.sortByValue);
    }
  }
  onGridClick(data) {
    const _bol = data.status;
    if (_bol) {
      this.displayGrid = true;
    } else {
      this.displayGrid = false;
    }
    let _element = this.dom.querySelectorAll("#frProduct");
    if (_element != null) {
      for (var i = 0; i < _element.length; i++) {
        if (!_bol) {
          _element[i].classList.remove("col-md-4");
          _element[i].classList.remove("col-sm-4");
          _element[i].classList.remove("col-xs-6");
          _element[i].classList.remove("col-xxs-6");

          _element[i].classList.add("col-md-6");
          _element[i].classList.add("col-sm-6");
          _element[i].classList.add("col-xs-12");
          _element[i].classList.add("col-xxs-12");
        } else {
          _element[i].classList.remove("col-md-6");
          _element[i].classList.remove("col-sm-6");
          _element[i].classList.remove("col-xs-12");
          _element[i].classList.remove("col-xxs-12");

          _element[i].classList.add("col-md-4");
          _element[i].classList.add("col-sm-4");
          _element[i].classList.add("col-xs-6");
          _element[i].classList.add("col-xxs-6");
        }
      }
    }
  }
    getCategoryData(id, facets, pageSize,sort?,viewflag?:boolean) {
      this.seoService.removeAlternateTags();
      const that = this;      
      const queryStatus = this.route.snapshot.queryParamMap.get("viewAllCat");
      const _parentCat =this.route.snapshot.params;
      const baseSite = this.singletonServ.catalogVersion;
      const _searchId = {
        id: id,
        catId: this.searchCatId
      };
      const indexArray = [];
      this.pageLoad = false;
      //scroll to on view more click
      this.nextLoadedProdPosition = window.scrollY;
      this.categoryLandingSubscription= this.categoryServ.getMBProduct(baseSite,id,pageSize,sort ? sort : this.sortByValue,this.viewallflag).subscribe(
        (resp:any) => { 
          if (!resp){
            this.pageLoad = true;
          }
         if(resp.keywordRedirectUrl){          
          this.router.navigate([resp.keywordRedirectUrl]);
         } else if(resp.extendedCategoryToBeRedirected && resp.extendedCategoryRedirect){          
          this.router.navigate([resp.extendedCategoryRedirect]);
         }
         else{
          resp['category']=(queryStatus||_parentCat&&_parentCat.categoryId)?undefined:that.categorybanner;
          this.categoryResponse = resp;
          if(resp['category']){
            this.secNavSlotid = resp['category'].secondaryNavigationSlot;
            if(resp['category'].secondaryNavigationSlot){
            //this.getAmpContent(this.secNavSlotid);
            if ($('.sec-nav-sub-category').css('display') == 'none') {this.getAmpContent(this.secNavSlotid);}
            }
               }
          _.forEach(resp["products"], function(value, i) {
            _.assignIn(value, {
              category: (queryStatus ||_parentCat&&_parentCat.categoryId)?undefined:that.categorybanner,
              show: false,
              ratingClass:(value.productAverageRating)?that.getRatingCls(value.productAverageRating):'rating-bg-hide',
              id: i + 1
            });
            if (value.isDummy) {
              // value["currentIndex"] = i;
              // if(value.position){
              //   let pos;
              //   if(value.position==1 ||value.position==0){
              //     pos=0;
              //   }else{
              //     pos=value.position-1;
              //   }
              //   if(pos<=resp["products"].length){
              //     const obj = {
              //       oldindex: i,
              //       newIndex:pos
              //     };
              //     indexArray.push(obj);
              //   }
              // }else{
              //   const obj = {
              //     oldindex: i,
              //     newIndex:4
              //   };
              //   indexArray.push(obj);
              // }
              //amplience new category banner
              that.ampService.getContentByKey(value.code).subscribe(resp=>{    
                if (resp['content']) {
                  that.contentBanner = resp['content'];  
                  _.assignIn(value, { htmlData: that.contentBanner });
                }
              });
            //   if(AmpCa.utils!==undefined){//undefined check has been added as old ampliance implementation was throwing error
            //   AmpCa.utils.getHtmlServiceData({
            //     auth: {
            //       baseUrl: "https://c1.amplience.net",
            //       id: value.code,
            //       store: "moltonbrown",
            //       templateName: "acc-template-card",
            //       locale: baseSite.locale
            //     },
            //     callback: function(data) {
            //       _.assignIn(value, { htmlData: data });
            //     }
            //   }); 
            // }
          }
          });
          const prodPaginator = resp["pagination"];
          this.pagination = resp["pagination"];
          //const products = resp["products"];
          //on view more push set of 12 products
          if(!this.viewallflag && !this.checkList){
            this.products=[];
            this.products=resp['products'];
          }
          else if(this.viewallflag && !this.checkList){
           this.viewallflag=false;//reset viewall flag
           //to show out of stock products at bottom of page
           const filteroutofstock = this.products.filter((item) => item.stock && item.stock['stockLevelStatus'] === "outOfStock");
           const nooutofstock=this.products.filter((item) => item.stock && !(item.stock['stockLevelStatus'] === "outOfStock"));
          if(filteroutofstock.length>0){
            this.products=nooutofstock;
          }
           for (var i=0; i<resp["products"].length; i++) {
             this.products.push(resp["products"][i])
           }
           const newproductslist=this.products.concat(filteroutofstock);
           this.products=newproductslist;
          }
          else if(this.checkList && !this.viewallflag)
          {
           this.products=[];
           for (var i=0; i<resp["products"].length; i++) {
             this.products.push(resp["products"][i])
           }
          }
          else if(this.checkList && this.viewallflag)
          {
             //to show out of stock products at bottom of page
             const filteroutofstockarr = this.products.filter((item) => item.stock && item.stock['stockLevelStatus'] === "outOfStock");
             const nooutofstockarr=this.products.filter((item) => item.stock && !(item.stock['stockLevelStatus'] === "outOfStock"));
             if(filteroutofstockarr.length>0){
               this.products=nooutofstockarr;
             }
           for (var i=0; i<resp["products"].length; i++) {
             this.products.push(resp["products"][i])
           }
           const newproductslistarr=this.products.concat(filteroutofstockarr);
           this.products=newproductslistarr;
           if(resp['pagination']['totalResults']==this.products.length)
           {
           this.viewallflag=false;//reset viewall flag
           }
          }
          if (indexArray.length >= 1) {
            const swapprod=this.products;
            _.forEach(indexArray, function(value, i) {
              that.swapproducts(swapprod, value.oldindex, value.newIndex);
            });
          }
          this.totalProducts = prodPaginator["totalResults"];
          this.pagedItems = this.products;
          this.activeProducts = this.products ? true : false;
          const _facetObj = {
            facets: resp["facets"],
            status: facets
          };
          this.facetResponse = _facetObj;
          this.pageLoad = true;          
        setTimeout(()=>{
          that.sendInitialFeed();
        });
        this.constructSEOTags(resp);
        setTimeout(()=>{
          window.scrollTo(0,this.nextLoadedProdPosition)
        },500);
      }
  },
        (err:any) => {
          this.pageLoad = true;
          if(err.error){
             if(err.error.errors){
                if(err.error.errors[0].type=='UnknownIdentifierError'){
                  this.router.navigateByUrl('store/404', { skipLocationChange: true });
                }
             }
          }

        }
      );
    }
    sendInitialFeed(){
      const baseSite = this.singletonServ.catalogVersion;
      this.gtmServe.gtmProductListPages(baseSite.currencyCode,[]);
    }
    getParentCategory(prdId,boolean,pagesize){
      const baseSite = this.singletonServ.catalogVersion;
      this.categoryLandingSubscription= this.categoryServ.getMBProduct(baseSite,prdId,pagesize,this.sortByValue,this.viewallflag).subscribe(
        (resp:any) => { 
          resp['category']=this.categorybanner;
          setTimeout(()=>{
            this.constructSEOTags(resp);
          },1500);
        });
    }
    getRatingCls(rating){
      const _rating = rating.toFixed(1);
      const _replaceRating = _rating.replace('.','_');
      return 'bv_image'+_replaceRating;
    }
  constructSEOTags(resp){
      const that=this;
      const _pathName=this.router.url;
      const categoryAlternateURLs = resp.categoryAlternateURLs;
      const extendedCategoryRedirect = resp.extendedCategoryRedirect;
      if(categoryAlternateURLs){
        if(categoryAlternateURLs.entry){
              const urls = categoryAlternateURLs.entry;
              for(let i = 0; i < urls.length; i++) {
                this.seoService.updateAlternateLink(urls[i].key, urls[i].value);
              }
        } else{
          this.seoService.removeAlternateTags();
        }
      } else{
        this.seoService.removeAlternateTags();
      } 
      //Updating the canonical URL for Category Page
      if(extendedCategoryRedirect) {
        this.seoService.createCanonicalURL(extendedCategoryRedirect);
      }
      else {
        this.seoService.createCanonicalURL(_pathName);
      }
      this.seoService.updateOGURL(_pathName);  
      if(resp.metaDescription){
        const htmlContent=`<div id="plpdescContent">${resp.metaDescription}</div>`;
        let parser = new DOMParser();
        let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
        const _innerText=parsedHtml.getElementById('plpdescContent').innerText;
        const _metaDescription= this.dom.querySelector("meta[name='description']");
        if(_metaDescription){
          _metaDescription.setAttribute('content', _innerText);
        } else {
          this.meta.updateTag({ name: 'description', content: _innerText });
        }
        this.meta.updateTag({ property: 'og:description', content: _innerText});
        this.meta.updateTag({ name: 'DC.description', content: _innerText });
    }
    if(resp.metaKeywords){
      const _DCelementKeyWord = this.dom.querySelector("meta[name='DC.subject']");
      if(_DCelementKeyWord){
        _DCelementKeyWord.setAttribute('content', resp.metaKeywords);
      }else {
        this.meta.updateTag({ name: 'DC.subject', content: resp.metaKeywords});
      }
    }
    if(that.categorybanner){
      this.seoService.updateTitle(that.categorybanner.categoryDisplayName);
    }
  }
  fetchCategoryProductNextperPage(data: any) {
    const status = data.status;
    //this.singletonServ.scrollToTarget('#moltonbrown');
    this.viewallflag=data.viewallflag;
    if (!status) {
      let page = this.pagination.currentPage + 1;
      let prdId = "/search?query=:relevance:allCategories:" + this.catId;
      const id = prdId + this.paths + "&currentPage=" + page;
      this.getCategoryData(id, false, this.pageSize, !this.sortByValue && this.enableBestSeller ? 'bestSeller' : '',this.viewallflag);
    } 
  }

//Secondary navigation
getAmpContent(slotId){    
  this.ampService.getContentByKey(slotId).subscribe(resp=>{    
    this.secNavContent = resp['content']['slotContent'][0];  
    this.secNavItems= resp['content']['slotContent'][0].secondaryNavigationItem;
    if(this.secNavContent.description && this.secNavContent.description.text) this.secNavContent.description.text = this.markdown.render(this.secNavContent.description.text);     
    if(resp['content']['slotContent'][0]){
    this.secNavigationSubCategory();
  }
  }); 
}
secNavigationSubCategory(){
  if(this.showShopCategory){
    setTimeout(() => {
    $('.sec-nav-sub-category').css('display','block');
    $('.slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      infinite: true,
      centerMode: true,
      draggable: false,
      centerPadding: '75px',
      nextArrow: '<div class="nav-arrow slick-next slick-arrow"><i class="icon-rightarrow-lg"></i></div>',
      prevArrow: '<span class="nav-arrow slick-prev slick-arrow leftarrow"><i class="icon-leftarrow-md" aria-hidden="true"></i></span>',      
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 875,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            draggable: true,
            centerPadding: '150px',
            infinite: true,
            dots: true,
            nextArrow: '<span class="nav-arrow slick-next slick-arrow"><i class="icon-rightarrow-md"></i></span>',
            prevArrow: '<span class="nav-arrow slick-prev slick-arrow"><i class="icon-leftarrow-md"></i></span>',
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            draggable: true,
            centerPadding: '150px',
            infinite: true,
            dots: true,
            nextArrow: '<span class="nav-arrow slick-next slick-arrow"><i class="icon-rightarrow-md"></i></span>',
            prevArrow: '<span class="nav-arrow slick-prev slick-arrow"><i class="icon-leftarrow-md"></i></span>',
          },
        },
      ]
    }); 
    if( $('.slick-prev').hasClass("leftarrow")){
      var currentSlide = $('.slider-nav').slick('slickCurrentSlide');
      $('.slick-prev').toggle(currentSlide != 0);
      $('.slider-nav').one('afterChange', function() {
      $('.slick-prev').show();
       }); 
      }
    });
  }
}

resetFilteredProducts(data){
  const prdId = "/search?query=:relevance:allCategories:" + this.catId;
    if (data.id.length == 0) {
      this.checkList = false;
    } else {
      this.checkList = true;
    }
    this.paths = data.id;
    this.searchPrdId = prdId + this.paths;
  this.getCategoryData(this.searchPrdId, false, 12, this.enableBestSeller && !this.sortByValue ? 'bestSeller' : '');
}

isCompetitionEntryOrThankyou(templateName) {
  return templateName?.includes('/competitionPage-entry') || templateName?.includes('/competitionPage-thankyou')
}

}
export const pageType = "Category Landing Page";