<section class="category-section" [ngSwitch]="switchPageType" *ngIf="isTemplateNameLoaded && !templateName">

  <section *ngSwitchCase="'staticpage'">
    <app-sale [categorybanner]="categorybanner"></app-sale>
  </section>
<section *ngSwitchCase="'productDetailCheck'">
  <app-product-detail-page></app-product-detail-page>
</section>
<section *ngSwitchCase="'categoryCheck'">
<div class="container plp-top-container">
<app-mb-bread-crumb *ngIf="breadcrumb.length" [breadcrumb]="breadcrumb"></app-mb-bread-crumb>
  <div  class="out-of-stock-cntnt" *ngIf="outofstock">
    <p [innerHtml]="outofstockMessage">
    </p>
  
   </div>
  <app-categorybanner
    *ngIf="categorybanner"
    [categorybanner]="categorybanner"
    [pagination]="pagination"
  ></app-categorybanner>
</div>

<main class="plp-view_container container" [ngClass]="{'hide-module' : previewId }">

  <!--Added for RW - Filters Starts-->
  <section>
    <div class="row plp-container-grid" *ngIf="activeProducts;else overlayTmpl">
      <div [ngClass]="{
          'container-display-block': catalogSpecific,
          'container-display-none': !catalogSpecific
        }" >
        <app-filter
          [openFacet]="openFacet"
          [searchDisplay]="false"
          [facetResponse]="facetResponse"
          [catId]="catId"
          [modal]="false"
          [isBestSeller]="enableBestSeller"
          (clearParams)="onClearAll($event)"
          (refetchProducts)="onRefetchProducts($event)"
          (clearFilter)="resetFilteredProducts($event)"
          (sortByChangeEvent)="sortByChange($event)">
        </app-filter>
      </div>
    </div>
  </section>
    <section>
      <div class="plp-container-grid" *ngIf="activeProducts">
          <div 
              class="plp-container-load-block"     
              [ngClass]="{ 
                'overlay-bg-show': !pageLoad, 
                'overlay-bg-hide': pageLoad 
              }">
              <div class="loading-gif m-auto text-center mt-20">
                <span class="loading-txt" *ngIf="singletonServ.translateData"> {{singletonServ.translateData.loadingTxt.loadingProducts}} </span> 
                <img width="150" height="150" alt="loading-icon" class="img-fluid pt-40" ngSrc="/assets/Redesign/loading-icon.gif">
               </div>
          </div>
        <div
          class="col-md-12"
          *ngIf="activeProducts"
          [ngClass]="{
            'col-md-12': catalogSpecific,
            'col-md-12 no-padding': !catalogSpecific
          }"
        >
          <div class="catalog_plp">
            <app-product
              [categoryResponse]="categoryResponse"
              [activeProducts]="activeProducts"
              [pagedItems]="pagedItems"
              [displayGrid]="displayGrid"
              (updateProduct)="onUpdateProduct($event)"
              [pagination]="pagination"
              (fetchperPage)="fetchCategoryProductNextperPage($event)"
              [products]="products"
              [pageGiftBuilder]="pageGiftBuilder"
            >
            </app-product>
          </div>
        </div>
      </div>
      <ng-template #overlayTmpl>
          <div class="plp-overlay">
            <div class="loading-gif m-auto text-center mt-20">
              <span class="loading-txt" *ngIf="singletonServ.translateData"> {{singletonServ.translateData.loadingTxt.loadingProducts}} </span> 
              <img width="150" height="150" alt="loading-icon" class="img-fluid pt-40" ngSrc="/assets/Redesign/loading-icon.gif">
             </div>
          </div>
      </ng-template>
    </section>
</main>

<!-- modal -->
<div 
  class="modal" 
  id="modalFacetPopup" 
  tabindex="-1" 
  role="dialog" 
  aria-hidden="true">
  <div class="modal-dialog modal-full" role="document">
    <app-filter
      [facetResponse]="facetResponse"
      [modal]="true"
      [catId]="catId"
      (clearParams)="onClearAll($event)"
      (refetchProducts)="onRefetchProducts($event)"
    >
    </app-filter>
  </div>
</div>
<!-- Shop by category starts here -->
<div class="sec-nav-sub-category"> 
<div class="sec-nav">
  <div [ngClass]="{'add-banner-marble' : secNavContent?.backgroundImage}">
  <div class="sec-nav-title">
    <h2 class="shop-category" *ngIf="secNavContent?.title">{{secNavContent?.title}}</h2>
  </div>
  <div class="desktop-only sec-nav-desc" [innerHtml]="secNavContent?.description?.text"></div> 
  <div class="slider slider-nav" id="slick-slider-nav">
    <div *ngFor="let items of secNavItems;let i = index">
    <div class="sec-nav-imgwrapper" [routerLink]="items?.options?.NavigationLink">
      <picture class="sec-nav-img-style">
        <source media="(max-width: 874px)"
        srcset="//media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=874&$banner-image-poi$ 1x, //media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=1536&$banner-image-poi$ 2x">              
        <source media="(min-width: 875px)"              
        srcset="//media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=1188 2x">              
        <img class="img-fluid w-100" width="328" height="328"    style="cursor: pointer;"         
        ngSrc="//media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=594"              
        srcset="//media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{items?.options?.navigationImage?.endpoint}}/{{items?.options?.navigationImage?.name}}?fmt=auto&w=1188 2x"              
        title="{{items?.options?.navigationImage?.name}}" alt="{{items?.options?.navigationImage?.altimagetext}}" (click)="items?.options?.navigationImage?.gtmProperties && gtmPromoClicks(banner.media.gtmProperties)" />              
        </picture>
      <div class="sec-nav-imgcaption"><span class="img-caption">{{items?.options?.navigationText}} </span></div></div>
    </div>
    </div>
</div>
</div>
</div>
</section>
</section>

<section>
  <div class="competition-block" *ngIf="templateName?.includes('/competitionPage-entry')">
    <div class="competition-block-formsPage" >
      <div class="comp-banner" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
        <div class="form-block" *ngIf="competitionPage?.title">
          <h1>{{competitionPage.title}}</h1>
          <div class="description" [innerHtml]="competitionPage?.description?.text"></div>
          <div *ngIf="competitionPage?.FreshRelevance?.dataKValue" class="tmspslot" [attr.data-k]="competitionPage?.FreshRelevance?.dataKValue" [attr.data-slid]="competitionPage?.FreshRelevance?.dataSlidValue" data-p="1" ></div>
        </div>
      </div>
    </div> 
  </div>
  <div class="thankyou-blk" *ngIf="templateName?.includes('/competitionPage-thankyou')">
    <div class="comp-banner" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
      <div class="form-block" *ngIf="competitionPage?.title">
        <h1>{{competitionPage.title}}</h1>
        <div class="description" [innerHtml]="competitionPage?.description?.text"></div>        
        <div class="buttons-holder">
          <span class="button" *ngIf="competitionPage?.buttonText">
            <a href="{{competitionPage?.buttonLink}}">
              <button class="btn-black" aria-label="competitionPage?.buttonText">{{competitionPage?.buttonText}}</button>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>
